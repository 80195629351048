import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ChangePasswordModel,
  ContactsModel,
  FrontendUserAddressModel,
  FrontendUserVerificationCodeModel,
  LoginModel,
  QuoteAcceptDetailModel,
  RequestParamModel,
  ResetPasswordModel,
  ResponseModel,
  SessionModel,
  UserModel
} from '@coremodels';
import {BaseService} from '@coreutils';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ThemeOptions} from "../theme-options";
import {SubscribersService} from "./subscribers.service";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;
  private profileModel: UserModel = new UserModel();

  constructor(
    private baseService: BaseService,
    private global: ThemeOptions,
    private subscribersService: SubscribersService
  ) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public isUserAuthenticated(): boolean {
    const token = localStorage.getItem('usertoken');
    // Check whether the token is expired and return
    // true or false
    return token != null && token != undefined;
  }

  public getUserProfile(): any {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.profileModel = JSON.parse(userData) as UserModel;
      return this.profileModel;
    } else {
      return null
    }
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  login(requestModel: LoginModel): Observable<SessionModel> {
    return this.baseService.login(ApiConstants.LOGIN_URL, requestModel)
      .pipe(map<SessionModel, SessionModel>(response => {
        return response;
      }, (err: any) => {
        return err;
      }));
  }

  loginWithFacebook(requestModel: any): Observable<SessionModel> {
    return this.baseService.postWithoutToken(ApiConstants.LOGIN_WITH_FACEBOOK_URL, requestModel)
      .pipe(map<SessionModel, SessionModel>(response => {
        return response;
      }, (err: any) => {
        return err;
      }));
  }

  loginWithGoogle(requestModel: any): Observable<SessionModel> {
    return this.baseService.postWithoutToken(ApiConstants.LOGIN_WITH_GOOGLE_URL, requestModel)
      .pipe(map<SessionModel, SessionModel>(response => {
        return response;
      }, (err: any) => {
        return err;
      }));
  }

  signUp(requestModel: UserModel): Observable<UserModel> {
    return this.baseService.postWithoutToken(ApiConstants.REGISTER_URL, requestModel)
      .pipe(map<any, UserModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  sendVerificationcode(FrontendUserVerificationCodeModel: FrontendUserVerificationCodeModel): Observable<ResponseModel> {
    return this.baseService.post(ApiConstants.USER_VERIFICATION_CODE_URL, FrontendUserVerificationCodeModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  reSendVerificationcode(FrontendUserVerificationCodeModel: FrontendUserVerificationCodeModel): Observable<ResponseModel> {
    return this.baseService.post(ApiConstants.USER_RESEND_VERIFICATION_CODE_URL, FrontendUserVerificationCodeModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  verifiyCode(FrontendUserVerificationCodeModel: FrontendUserVerificationCodeModel): Observable<ResponseModel> {
    return this.baseService.post(ApiConstants.USER_VERIFY_CODE_URL, FrontendUserVerificationCodeModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  sendQuoteDetailVerificationCode(quoteAcceptDetailModel: QuoteAcceptDetailModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_VERIFICATION_CODE_URL, quoteAcceptDetailModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  reSendQuoteDetailVerificationCode(quoteAcceptDetailModel: QuoteAcceptDetailModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_RESEND_VERIFICATION_CODE_URL, quoteAcceptDetailModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  verifyQuoteDetailVerificationCode(quoteAcceptDetailModel: QuoteAcceptDetailModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_VERIFY_CODE_URL, quoteAcceptDetailModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  getLoginUserDetail(requestParamModel?: RequestParamModel): Observable<any> {
    return this.baseService.get(ApiConstants.USER_DETAIL_URL, requestParamModel)
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getSettingData(requestParamModel?: RequestParamModel): Observable<any> {
    return this.baseService.getWithoutToken(ApiConstants.SETTINGS_URL, requestParamModel)
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getSettingViewDetail(): Observable<any> {
    return this.baseService.get(ApiConstants.SETTING_DETAIL_URL)
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  forgotPassword(forgotPasswordModel: any): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.USER_FORGOT_PASSWORD_URL, forgotPasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  changePassword(changePasswordModel: ChangePasswordModel): Observable<ResponseModel> {
    return this.baseService.post(ApiConstants.USER_CHANGE_PASSWORD_URL, changePasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  resetPassword(resetPasswordModel: ResetPasswordModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.USER_RESET_PASSWORD_URL, resetPasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  updateProfile(requestModel: UserModel): Observable<UserModel> {
    return this.baseService.patch(ApiConstants.USER_UPDATE_DETAIL_URL, requestModel)
      .pipe(map<any, UserModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  updateProfilePicture(requestModel: any): Observable<any> {
    return this.baseService.uploadPost(ApiConstants.USER_UPDATE_PROFILE_PICTURE_URL, requestModel)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  getUserDetails(idUser: number, requestParamModel: RequestParamModel): Observable<ContactsModel> {
    return this.baseService.get('contact/' + idUser, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as ContactsModel;
      }, (err: any) => {
        return err;
      }));
  }

  updateUserAddressDetails(frontendUserAddressModel: any): Observable<FrontendUserAddressModel> {
    return this.baseService.post(ApiConstants.USER_UPDATE_ADDRESS_URL, frontendUserAddressModel).pipe(map<any, FrontendUserAddressModel>(response => {
        let userModel = response.data as FrontendUserAddressModel;
        return userModel;
      }, (err: any) => {
        return err;
      })
    );
  }

  contactUs(body: any) {
    return this.baseService.postWithoutToken('contact-us', body).pipe(
      map<any, any>(response => {
        return response;
      }, (error: HttpErrorResponse) => {
        return error;
      })
    )
  }

  clearToken(): void {
    this.global.rightSidebarAccountLoader = false;
    this.subscribersService.rightSidebarAccountLoaderToggle(this.global.rightSidebarAccountLoader);
    this.global.registrationForm = '';
    this.subscribersService.registrationFormToggle(this.global.registrationForm);
    localStorage.removeItem('usertoken');
    localStorage.removeItem('loader');
    localStorage.removeItem('user');
    localStorage.removeItem('orderTotalCount');
    localStorage.removeItem('quotesActiveCount');
    localStorage.removeItem('faviouriteProductCount');
    localStorage.removeItem('gathercoCredit');
  }
}
