import {Component, Inject, OnDestroy, OnInit, Optional, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {Response} from 'express'
import {RESPONSE} from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  currentYear: number = new Date().getFullYear();
  constants = Constants;
  response: Response;

  /* ################### Variable Declaration End ################### */
  constructor(
    public router: Router,
    public renderer: Renderer2,
    @Optional() @Inject(RESPONSE) response: any
  ) {
    this.response = response;
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    this.renderer.addClass(document.body, 'not-found');
    if (this.response) {
      // response will only be if we have express
      this.response.status(404);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'not-found');
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  onHeader() {
    this.router.navigate(['/']);
  }

}
