<div class="quote-tabs">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link active" data-toggle="tab"  [routerLink]="['/walling']">
        All Walling
        <i class='fas fa-arrow-right ml-3'></i>
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active">
      <div class="main-sub-title">
        <app-filter-by-walling (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event)">
        </app-filter-by-walling>
      </div>
    </div>
  </div>
</div>
