import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  /* ################### Variable Declaration Star ################### */
  public onClose!: Subject<boolean>;
  @Input() title!: string;
  @Input() message!: string;
  @Input() confirmationButtonText?: string = "Confirm";
  @Input() cancelButtonText?: string = "Cancel";

  /* ################### Variable Declaration End ################### */

  constructor(
    private activeModal: NgbActiveModal,
  )
   {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    this.onClose = new Subject();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  confirm(): void {
    this.activeModal.close(true);
  }

  decline(): void {
    this.activeModal.close(false);
  }

  /************************************
   * Other functions end
   ************************************/
}
