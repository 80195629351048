<div class="custom-container-main">
  <ng-container *ngIf='checkTemplateRequired()'>
    <div class="main_wrapper">
      <app-header></app-header>
      <app-right-sidebar *ngIf='is_sidebar_open == true'></app-right-sidebar>
      <main class=""
            [ngClass]="{'account-pages' : currentRoute == 'account', 'small-content-pages': (currentRoute == '/refund-policy' || currentRoute == '/privacy-policy')}">
        <ng-container *ngTemplateOutlet="template"></ng-container>
        <app-sample-order-right-sidebar *ngIf='is_sample_order_sidebar_open == true'></app-sample-order-right-sidebar>
      </main>
      <app-footer></app-footer>
    </div>
  </ng-container>
  <ng-container *ngIf='!checkTemplateRequired()'>
    <app-right-sidebar *ngIf='is_sidebar_open == true'></app-right-sidebar>
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>

  <ng-template #template>
    <router-outlet>
    </router-outlet>
  </ng-template>
</div>
