import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sample-order-confirmation',
  templateUrl: './sample-order-confirmation.component.html',
  styleUrls: ['./sample-order-confirmation.component.css']
})
export class SampleOrderConfirmationComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  isAcceptButtonClicked = false;
  /* ################### Variable Declaration End ################### */

  constructor(
    public ActiveModel: NgbActiveModal
  ) {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  onAccept() {
    this.isAcceptButtonClicked = true;  // Disable button after the first click
    this.ActiveModel.close(true)
  }

  /************************************
   * Other functions end
   ************************************/
}
