<div class="error-page-background">
  <main class="error-page-padding">
    <div class="container">
      <div class="row text-center min-height">
        <div class="col-lg-10 offset-lg-1 col-sm-6 offset-sm-3 col-12 p-3 error-main padding-50">
          <h1 class="m-0">404</h1>
          <h6>Page not found</h6>
          <a [routerLink]="['/']" class="btn white-bordered-btn color-black">Home Page</a>
        </div>
      </div>
    </div>
  </main>
</div>
