import {Injectable} from '@angular/core';
import {BaseService} from "@coreutils";
import {MaterialModel, PaginationModel, ProductModel, RequestParamModel} from "@coremodels";
import {map} from "rxjs/operators";
import {Observable} from 'rxjs';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class FaviouritesService {

  constructor(
    private baseService: BaseService
  ) {
  }

  // Get faviourite
  getProduct(requestParamModel: RequestParamModel): Observable<any> {
    return this.baseService.get(ApiConstants.FAVOURITE_PRODUCT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // Update faviourite product
  updateProductFaviourite(body: any): Observable<any> {
    return this.baseService.post(ApiConstants.PRODUCT_SET_AS_FAVOURITE_URL, body).pipe(map<any, any>(response => {
        let responseData = response.data;
        return responseData;
      }, (err: any) => {
        return err;
      })
    );
  }

}
