import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class AlertService {

  constructor(
    private toasterService: ToastrService,
  ) {
  }

  success(message: string, title?: string) {
    title = title || 'Success';
    this.toasterService.success(message, title, {
      closeButton: true,
      progressBar: true,
    });
  }

  error(message: string, title?: string) {
    title = title || 'Error';
    this.toasterService.error(message, title, {
      closeButton: true,
      progressBar: true,
    });
  }

  info(message: string, title?: string) {
    title = title || 'Info';
    this.toasterService.info(message, title, {
      closeButton: true,
      progressBar: true,
    });
  }

  warning(message: string, title?: string) {
    title = title || 'Warning';
    this.toasterService.warning(message, title, {
      closeButton: true,
      progressBar: true,
    });
  }
}
