import {Injectable} from '@angular/core';
import {BaseService} from "@coreutils";
import {ColorModel, PaginationModel, RequestParamModel} from "@coremodels";
import {map} from "rxjs/operators";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor(private baseService: BaseService) {
  }

  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.COLOR_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<ColorModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as ColorModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

}
