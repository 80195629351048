import {Injectable} from '@angular/core';
import {GatherCreditCompany, PaginationModel, RequestParamModel, GathercoCreditTransaction} from '@coremodels';
import {BaseService} from '@coreutils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class GatherCommitmentService {

  constructor(public baseService: BaseService) {
  }

  getCredit(requestParamModel: RequestParamModel, id: any): Observable<GatherCreditCompany> {
    return this.baseService.get(ApiConstants.GATHERCO_CREDIT_DETAIL_URL + id, requestParamModel)
      .pipe(map<any, GatherCreditCompany>(response => {
        const data = response.body.data as GatherCreditCompany;
        return data;
      }, (err: any) => {
        return err;
      }));
  }

  donateCredit(data: any): any {
    return this.baseService.post(ApiConstants.GATHERCO_CREDIT_DONATE_URL, data).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  indexGetCredit(requestParamModel: RequestParamModel): Observable<GatherCreditCompany> {
    return this.baseService.get(ApiConstants.GATHERCO_CREDIT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<GatherCreditCompany>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as GatherCreditCompany[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  getGatherCoCreditTransactions(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.GATHERCO_CREDIT_TRANSACTION_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<GathercoCreditTransaction>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as GathercoCreditTransaction[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getCounter(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.GATHERCO_CREDIT_TRANSACTION_COUNTER_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }
}
