import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, EventEmitter, Output} from '@angular/core';
import {SubscribersService} from 'src/app/core/services/subscribers.service';
import {ThemeOptions} from 'src/app/core/theme-options';
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {CommunicationService} from 'src/app/core/services/communication.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  rightDrawerToggle: boolean = false;
  rightSidebarLoaderToggle: boolean = false;
  formType: string = '';
  is_cross: boolean = false;
  tabName?: string;
  isBrowser: any;
  isServer: any;
  isPageDetailLoad = true;
  loginTab: boolean = false

  /* ################### Variable Declaration End ################### */

  constructor(
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private changeDetectionRef: ChangeDetectorRef,
    private communicationService: CommunicationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.subscriberService.rightDrawer.subscribe((options) => {
      this.is_cross = false
      this.globals.rightSidebar = options;
      this.rightDrawerToggle = options;
      this.changeDetectionRef.markForCheck();
      setTimeout(function () {
        localStorage.setItem("is_open_login_sidebar", "false");
      }, 500);
    });
    this.subscriberService.isAccountLoader.subscribe((options) => {
      this.globals.rightSidebarAccountLoader = options;
      this.rightSidebarLoaderToggle = options;
      this.changeDetectionRef.markForCheck();
    });
    this.subscriberService.registrationTabForm.subscribe((options) => {
      this.globals.registrationForm = options;
      this.formType = options;
      this.changeDetectionRef.markForCheck();
    });

    if (isPlatformServer(platformId)) {
      this.isPageDetailLoad = false;
    } else {
      this.isPageDetailLoad = true;
    }
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
  }

  ngAfterViewInit() {
}

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  rightSidebarToggle() {
    this.is_cross = true;
    this.changeDetectionRef.detectChanges()
    this.globals.rightSidebar = false;
    this.subscriberService.rightDrawerToggle(this.globals.rightSidebar)
    setTimeout(() => {
      this.communicationService.emitRightSidebarClick(false);
    }, 500);
  }

  registerForm() {
    this.globals.registrationForm = 'trade';
    this.subscriberService.registrationFormToggle(this.globals.registrationForm);
  }

  loginForm() {
    this.globals.registrationForm = '';
    this.subscriberService.registrationFormToggle(this.globals.registrationForm);
  }

  onTabChange(event: any) {
    this.tabName = event;
    this.loginTab = true
  }

  /************************************
   * Other functions end
   ************************************/
}
