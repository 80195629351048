import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal.component';


@Injectable()
export class ConfirmModalService {

    constructor(private modalService: NgbModal) { }

    public confirm(title: string, message: string, windowCustomClass: any = null): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmModalComponent, {
            // size: 'sm',
            centered: true,
            windowClass: 'delete-modal-custom ' + windowCustomClass
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        return modalRef.result;
    }
}
