import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AccountAddressModel,
  AccountModel,
  ContactsModel,
  FrontendUserAddressModel,
  RequestParamModel,
  TradeTypeModel
} from '@coremodels';
import {BaseService} from '@coreutils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PaginationModel} from '../models/pagination.model';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private baseService: BaseService) {
  }

  getAccountByABN(requestModel: AccountModel): any {
    return this.baseService.postWithoutToken(ApiConstants.ACCOUNT_BY_ABN_URL, requestModel)
      .pipe(map<any, AccountModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  getAccountDetails(accountId: number, requestParamModel: RequestParamModel): Observable<AccountModel> {
    return this.baseService.get(ApiConstants.ACCOUNT_DETAIL_URL + accountId, requestParamModel)
      .pipe(map<any, AccountModel>(response => {
        return response.body.data as AccountModel;
      }, (err: any) => {
        return err;
      }));
  }

  updateAccountDetails(accountModel: AccountModel): Observable<AccountModel> {
    return this.baseService.patch(ApiConstants.ACCOUNT_UPDATE_DETAIL_URL + accountModel.id, accountModel).pipe(map<any, AccountModel>(response => {
      accountModel = response.data as AccountModel;
      return accountModel;
    }, (err: any) => {
      return err;
    }));
  }

  updateAccountAddressDetails(accountAddressModel: any): Observable<AccountAddressModel> {
    return this.baseService.post('account-address', accountAddressModel).pipe(map<any, AccountAddressModel>(response => {
        let accountModel = response.data as AccountAddressModel;
        return accountModel;
      }, (err: any) => {
        return err;
      })
    );
  }

  updateUserAddressDetails(frontendUserAddressModel: any): Observable<FrontendUserAddressModel> {
    return this.baseService.post(ApiConstants.USER_UPDATE_ADDRESS_URL, frontendUserAddressModel).pipe(map<any, FrontendUserAddressModel>(response => {
        let userModel = response.data as FrontendUserAddressModel;
        return userModel;
      }, (err: any) => {
        return err;
      })
    );
  }

  updateAccountAddress(model: any): Observable<number> {
    return this.baseService.patch(ApiConstants.ACCOUNT_UPDATE_ADDRESS_URL + model.id, model)
      .pipe(map<any, number>(response => {
          model = response.data as any;
          return model.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateHomeAddress(model: any): Observable<number> {
    return this.baseService.patch(ApiConstants.USER_UPDATE_ADDRESS_URL + '/' + model.id, model)
      .pipe(map<any, number>(response => {
          model = response.data as any;
          return model.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  getTradeTypeLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.ACCOUNT_TRADE_TYPE_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<TradeTypeModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as TradeTypeModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

  getBusinessRoleList(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.ACCOUNT_BUSINESS_ROLE_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<TradeTypeModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as TradeTypeModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

  getUserDetails(requestParamModel: RequestParamModel): Observable<ContactsModel[]> {
    return this.baseService.get('contact', requestParamModel).pipe(map<any, ContactsModel[]>(response => {
        return response.body.data as ContactsModel[];
      }, (err: any) => {
        return err;
      })
    );
  }


  uploadLogo(_id: any, body: any) {
    return this.baseService.uploadPost(ApiConstants.ACCOUNT_UPDATE_LOGO_URL + _id, body)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }
}
