import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mega-menu-flooring',
  templateUrl: './mega-menu-flooring.component.html',
  styleUrls: ['./mega-menu-flooring.component.css']
})
export class MegaMenuFlooringComponent implements OnInit{
  /* ################### Variable Declaration Start ################### */
  @Output("redirectFromMegaMenu") redirectFromMegaMenu: EventEmitter<any> = new EventEmitter();

  /* ################### Variable Declaration End ################### */

  /************************************
  * Life cycle hooks start
  ************************************/
  ngOnInit() {
  }

  /************************************
  * Life cycle hooks end
  ************************************/

  /************************************
  * Redirect functions start
  ************************************/
  redirectFromChildMegaMenu($event: any) {
    this.redirectFromMegaMenu.emit($event);
  }

  /************************************
  * Redirect functions end
  ************************************/

}
