<div class="register-tab">
  <div class="tab-content">
    <div class="tab-pane" id="trade" [ngClass]="{'active': formType == 'trade'}">
      <div class="content-launch">
        <h4 class="welcome-title">Create a trade account</h4>
        <p><span class="link" (click)="eligibilityCriteriaPdf()">View</span> our trade account eligibility
          criteria</p>
      </div>
      <form [formGroup]="tradeForm" (ngSubmit)="tradeFormSubmit()" id="trade-register-form">
        <div class="register-form-field-grp panel-tab-section">
          <div class="row" *ngIf="tradeLogo != null && tradeLogo != ''">
            <div class="col">
              <div class="profile-photo-block">
                <div class="profile-photo">
                  <div class="profile-photo-placeholder">
                    <img src="{{ tradeLogo }}" alt="Logo"
                      (error)="helperService.getErrorImage($event, 'default_profile.png')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">ABN <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['abn'].invalid && tradeForm.controls['abn'].touched || submitted) || tradeForm.controls['abn'].errors?.apiError,
                'mobile-mask-class': tradeForm.controls['abn'].value,'is-valid' : !(tradeForm.controls['abn'].invalid)}" formControlName="abn"
                type='text' [imask]="{mask: '00 000 000 000',lazy:false}" [unmask]="true" required
                placeholder="Enter abn">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['abn'].invalid && tradeForm.controls['abn'].touched || submitted) || tradeForm.controls['abn'].errors?.apiError">
                <span *ngIf="tradeForm.controls['abn'].errors?.required">Please enter ABN</span>
                <span
                  *ngIf="!tradeForm.controls['abn'].errors?.required && tradeForm.controls['abn'].errors?.abnNotValid">Please
                  enter a valid ABN</span>
                <span
                  *ngIf="tradeForm.controls['abn'].errors?.apiError">{{tradeForm.controls['abn'].errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col" *ngIf="tradeType && tradeType.length > 0 && !isShowTradeTypeDropdown">
              <div class="are-you-dropdown">
                <label class="form-label">Companies Primary Activity <span class="required-field">*</span></label>
                <div ngbDropdown class="d-block" [ngClass]="{ 'is-invalid': (tradeForm.controls['trade_type_id'].invalid && tradeForm.controls['trade_type_id'].touched || submitted) || tradeForm.controls['trade_type_id'].errors?.apiError,
                'is-valid' : !(tradeForm.controls['trade_type_id'].invalid)}">
                  <button class="btn delete-confirmation-popup" id="tradeDropdown" ngbDropdownToggle
                    (click)="$event.preventDefault()">
                    {{ selectedTradeType }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="tradeDropdown">
                    <a ngbDropdownItem *ngFor="let trade of tradeType"
                      (click)="changeTradeType(trade); $event.preventDefault()">
                      {{ trade.name }}
                    </a>
                  </div>
                </div>
                <span class="invalid-feedback" role="alert"
                  *ngIf="(tradeForm.controls['trade_type_id'].invalid && tradeForm.controls['trade_type_id'].touched || submitted) || tradeForm.controls['trade_type_id'].errors?.apiError">
                  <span *ngIf="tradeForm.controls['trade_type_id'].errors?.required">Please select
                    companies primary
                    activity</span>
                </span>
              </div>
            </div>
            <div class="col">
              <label class="form-label">Company Name <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['company_name'].invalid && tradeForm.controls['company_name'].touched || submitted) || tf.company_name.errors?.apiError, 'is-valid' : !(tradeForm.controls['company_name'].invalid),'field-disable disable': userDetailsDisable}"
                formControlName="company_name" type="text" required placeholder="Enter company name"
                (keydown.enter)="$event.preventDefault()" [readonly]="userDetailsDisable">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['company_name'].invalid && tradeForm.controls['company_name'].touched || submitted) || tf.company_name.errors?.apiError">
                <span *ngIf="tf.company_name.errors?.required">Please enter company name</span>
                <span *ngIf="tf.company_name.errors?.apiError">{{tf.company_name.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Business Address Line 1 <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['address_line_1'].invalid && tradeForm.controls['address_line_1'].touched || submitted) || tf.address_line_1.errors?.apiError || tf.address_line_1.errors?.streetRequired, 'is-valid' : !(tradeForm.controls['address_line_1'].invalid)}"
                formControlName="address_line_1" type="text" ngx-google-places-autocomplete [options]="options"
                #placesRef="ngx-places" placeholder="" (onAddressChange)="handleCompanyAddressChange($event)" required
                placeholder="Enter business address line 1" (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['address_line_1'].invalid && tradeForm.controls['address_line_1'].touched || submitted) || tf.address_line_1.errors?.apiError || tf.address_line_1.errors?.streetRequired">
                <span *ngIf="tf.address_line_1.errors?.required">Please enter business address line
                  1</span>
                <span
                  *ngIf="tf.address_line_1.errors?.streetRequired">{{tf.address_line_1.errors?.streetRequired}}</span>
                <span *ngIf="tf.address_line_1.errors?.apiError">{{tf.address_line_1.errors?.apiError}}</span>
                <span
                  *ngIf="!tradeForm.controls['address_line_1'].errors?.required && tradeForm.controls['address_line_1'].errors?.addressNotValid">PO-box's
                  may
                  not be nominated, as couriers cannot deliver samples to these addresses.</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Business Address Line 2</label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['address_line_2'].invalid && tradeForm.controls['address_line_2'].touched || submitted) || tf.address_line_2.errors?.apiError, 'is-valid' : !(tradeForm.controls['address_line_2'].invalid)}"
                formControlName="address_line_2" type="text" placeholder="Enter business address line 2"
                (keydown.enter)="$event.preventDefault()">
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Suburb <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['suburb'].invalid && tradeForm.controls['suburb'].touched || submitted) || tf.suburb.errors?.apiError, 'is-valid' : !(tradeForm.controls['suburb'].invalid)}"
                formControlName="suburb" type="text" placeholder="Enter suburb" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['suburb'].invalid && tradeForm.controls['suburb'].touched || submitted) || tf.suburb.errors?.apiError">
                <span *ngIf="tf.suburb.errors?.required">Please enter suburb</span>
                <span *ngIf="tf.suburb.errors?.apiError">{{tf.suburb.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">City <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['town_city'].invalid && tradeForm.controls['town_city'].touched || submitted) || tf.town_city.errors?.apiError, 'is-valid' : !(tradeForm.controls['town_city'].invalid)}"
                formControlName="town_city" type="text" placeholder="Enter city" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['town_city'].invalid && tradeForm.controls['town_city'].touched || submitted) || tf.town_city.errors?.apiError">
                <span *ngIf="tf.town_city.errors?.required">Please enter city</span>
                <span *ngIf="tf.town_city.errors?.apiError">{{tf.town_city.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">State <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['state_region'].invalid && tradeForm.controls['state_region'].touched || submitted) || tf.state_region.errors?.apiError, 'is-valid' : !(tradeForm.controls['state_region'].invalid)}"
                formControlName="state_region" type="text" placeholder="Enter state" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['state_region'].invalid && tradeForm.controls['state_region'].touched || submitted) || tf.state_region.errors?.apiError">
                <span *ngIf="tf.state_region.errors?.required">Please enter state</span>
                <span *ngIf="tf.state_region.errors?.apiError">{{tf.state_region.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">Postal Code <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['post_code'].invalid && tradeForm.controls['post_code'].touched || submitted) || tf.post_code.errors?.apiError, 'is-valid' : !(tradeForm.controls['post_code'].invalid)}"
                formControlName="post_code" type="text" placeholder="Enter postal code" required numbersOnly
                maxlength="4" (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['post_code'].invalid && tradeForm.controls['post_code'].touched || submitted) || tf.post_code.errors?.apiError">
                <span *ngIf="tf.post_code.errors?.required">Please enter postal code</span>
                <span *ngIf="tf.post_code.errors?.pattern">Please enter valid postal code</span>
                <span *ngIf="tf.post_code.errors?.apiError">{{tf.post_code.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Country <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['country'].invalid && tradeForm.controls['country'].touched || submitted) || tf.country.errors?.apiError, 'is-valid' : !(tradeForm.controls['country'].invalid)}"
                formControlName="country" type="text" placeholder="Enter country" required>
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['country'].invalid && tradeForm.controls['country'].touched || submitted) || tf.country.errors?.apiError">
                <span *ngIf="tf.country.errors?.required">Please enter country</span>
                <span *ngIf="tf.country.errors?.apiError">{{tf.country.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Website <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['website'].invalid && tradeForm.controls['website'].touched || submitted) || tf.website.errors?.apiError, 'is-valid' : !(tradeForm.controls['website'].invalid)}"
                formControlName="website" type="text" required placeholder="Enter website"
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['website'].invalid && tradeForm.controls['website'].touched || submitted) || tf.website.errors?.apiError">
                <span *ngIf="tf.website.errors?.required">Please enter website</span>
                <span *ngIf="tf.website.errors?.pattern">Please enter valid url.</span>
                <span *ngIf="tf.website.errors?.apiError">{{tf.website.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">First Name <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['first_name'].invalid && tradeForm.controls['first_name'].touched || submitted) || tf.first_name.errors?.apiError, 'is-valid' : !(tradeForm.controls['first_name'].invalid)}"
                formControlName="first_name" type="text" required placeholder="Enter first name"
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['first_name'].invalid && tradeForm.controls['first_name'].touched || submitted) || tf.first_name.errors?.apiError">
                <span *ngIf="tf.first_name.errors?.required">Please enter first name</span>
                <span
                  *ngIf="!tf.first_name.errors?.required && tradeForm.controls['first_name'].hasError('whitespace')">
                  First name cannot contain whitespace
                </span>
                <span
                  *ngIf="!tf.first_name.errors?.required && tf.first_name.errors !== null && tf.first_name.errors">Please
                  enter only alphabets</span>
                <span *ngIf="tf.first_name.errors?.apiError">{{tf.first_name.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">Last Name <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['last_name'].invalid && tradeForm.controls['last_name'].touched || submitted) || tf.last_name.errors?.apiError, 'is-valid' : !(tradeForm.controls['last_name'].invalid)}"
                formControlName="last_name" type="text" required placeholder="Enter last name"
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['last_name'].invalid && tradeForm.controls['last_name'].touched || submitted) || tf.last_name.errors?.apiError">
                <span *ngIf="tf.last_name.errors?.required">Please enter last name</span>
                <span *ngIf="!tf.last_name.errors?.required && tradeForm.controls['last_name'].hasError('whitespace')">
                  Last name cannot contain whitespace
                </span>
                <span
                  *ngIf="!tf.last_name.errors?.required &&  tf.last_name.errors !== null && tf.last_name.errors">Please
                  enter only alphabets</span>
                <span *ngIf="tf.last_name.errors?.apiError">{{tf.last_name.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Job Title</label>
              <input class="form-control" formControlName="job_title" type="text" placeholder="Enter job title"
                (keydown.enter)="$event.preventDefault()">
            </div>
            <!-- {{business_role}} -->
            <div class="col">
              <div class="are-you-dropdown" ngbDropdown>
                <label class="form-label">Business Role <span class="required-field">*</span></label>
                <div ngbDropdown class="d-block" [ngClass]="{ 'is-invalid': (tradeForm.controls['business_role'].invalid && tradeForm.controls['business_role'].touched || submitted) || tradeForm.controls['business_role'].errors?.apiError,
                'is-valid' : !(tradeForm.controls['business_role'].invalid)}"
                  (keydown.enter)="$event.preventDefault()">
                  <button class="btn delete-confirmation-popup" id="businessRoleDropdown"
                    ngbDropdownToggle (click)="$event.preventDefault()">
                    {{ selectedBusinessRoleType }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="businessRoleDropdown">
                    <a ngbDropdownItem *ngFor="let businessRoleData of businessRole"
                      (click)="businessList(businessRoleData); $event.preventDefault()">
                      {{ businessRoleData.name }}
                    </a>
                  </div>
                </div>
                <span class="invalid-feedback" role="alert"
                  *ngIf="(tradeForm.controls['business_role'].invalid && tradeForm.controls['business_role'].touched || submitted) || tradeForm.controls['business_role'].errors?.apiError">
                  <span *ngIf="tradeForm.controls['business_role'].errors?.required">Please select
                    business role</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Mobile <span class="required-field">*</span></label>
              <input class="form-control" [imask]="{mask: '0000 000 000',lazy:false}" [unmask]="true"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['mobile'].invalid && tradeForm.controls['mobile'].touched || submitted) || tf.mobile.errors?.apiError,
                'is-valid' : !(tradeForm.controls['mobile'].invalid) ,'mobile-mask-class': tradeForm.controls['mobile'].value }" formControlName="mobile" type='text' required placeholder="Enter mobile"
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['mobile'].invalid && tradeForm.controls['mobile'].touched || submitted) || tf.mobile.errors?.apiError">
                <span *ngIf="tf.mobile.errors?.required">Please enter mobile</span>
                <span *ngIf="tf.mobile.errors?.minlength && !tradeForm.controls['mobile'].errors?.mobileNotValid">Please
                  enter at least 10 characters</span>
                <span
                  *ngIf="!tradeForm.controls['mobile'].errors?.required && tradeForm.controls['mobile'].errors?.mobileNotValid">Must
                  be a valid australian mobile number</span>
                <span *ngIf="tf.mobile.errors?.apiError">{{tf.mobile.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Email <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['email'].invalid && tradeForm.controls['email'].touched || submitted) || tf.email.errors?.apiError, 'is-valid' : !(tradeForm.controls['email'].invalid)}"
                formControlName="email" type="text" required placeholder="Enter email"
                (change)="confirmEmailAddress($event)" (keydown.enter)="confirmEmailAddress($event)"
                (keydown.enter)="$event.preventDefault()" autocomplete="off">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['email'].invalid && tradeForm.controls['email'].touched || submitted) || tf.email.errors?.apiError">
                <span *ngIf="tf.email.errors?.required">Please enter email</span>
                <span *ngIf="tf.email?.hasError('pattern')">Please enter a valid email address</span>
                <span *ngIf="tf.email.errors?.apiError">{{ tf.email.errors?.apiError }}</span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label register-confirm-email-address">Confirm Email <span
                  class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['confirm_email'].invalid && tradeForm.controls['confirm_email'].touched || submitted) || tf.confirm_email.errors?.apiError, 'is-valid' : !(tradeForm.controls['confirm_email'].invalid)}"
                formControlName="confirm_email" type="text" required placeholder="Confirm email" onpaste="return false"
                id="registerconfirmemailaddress" (keydown.enter)="$event.preventDefault()" autocomplete="off">
              <span class="invalid-feedback confirm-email-address-3" role="alert">
                <span
                  *ngIf="(tradeForm.controls['confirm_email'].invalid && tradeForm.controls['confirm_email'].touched || submitted) || tf.confirm_email.errors?.apiError">
                  <span *ngIf="tf.confirm_email.errors?.required">Please enter confirm email</span>
                  <span *ngIf="tf.confirm_email?.hasError('pattern')">Please enter a valid confirm
                    email address</span>
                  <span
                    *ngIf="!tf.confirm_email.errors?.required && !tf.confirm_email?.hasError('pattern') && tf.confirm_email.errors?.confirmEmailNotMatch">Email
                    and Confirm Email does not
                    match</span>
                  <span *ngIf="tf.confirm_email.errors?.apiError">{{ tf.confirm_email.errors?.apiError
                    }}</span>
                </span>
              </span>
            </div>
          </div>
          <div class="row" *ngIf="isShowFormOtherField == true">
            <div class="col">
              <label class="form-label">Password <span class="required-field">*</span></label>
              <input formControlName="password"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['password'].invalid && tradeForm.controls['password'].touched || submitted) || tf.password.errors?.apiError, 'is-valid' : !(tradeForm.controls['password'].invalid)}"
                class="form-control" type="password" required autocomplete="current-password"
                placeholder="Enter password" (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['password'].invalid && tradeForm.controls['password'].touched || submitted) || tf.password.errors?.apiError">
                <span *ngIf="tf.password.errors?.required">Please enter password</span>
                <span *ngIf="tf.password.errors?.minlength">Please enter at least 6 characters</span>
                <span *ngIf="tf.password.errors?.apiError">{{tf.password.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">Confirm Password <span class="required-field">*</span></label>
              <input formControlName="confirm_password"
                [ngClass]="{ 'is-invalid': (tradeForm.controls['confirm_password'].invalid && tradeForm.controls['confirm_password'].touched || submitted) || tf.confirm_password.errors?.apiError, 'is-valid' : !(tradeForm.controls['confirm_password'].invalid)}"
                class="form-control form-control-lg" type="password" required placeholder="Confirm password"
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(tradeForm.controls['confirm_password'].invalid && tradeForm.controls['confirm_password'].touched || submitted) || tf.confirm_password.errors?.apiError">
                <span *ngIf="tf.confirm_password.errors?.required">Please enter confirm password</span>
                <span
                  *ngIf="!tf.confirm_password.errors?.required && tf.confirm_password.errors?.passwordNotMatch">Password
                  and Confirm Password does not
                  match</span>
                <span *ngIf="tf.confirm_password.errors?.apiError">{{tf.confirm_password.errors?.apiError}}</span>
              </span>
            </div>
          </div>

          <div class="row form-submit-btn mt-0">
            <div class="col-12">
              <button type="submit" class="btn btn-primary grey-bg-btn">
                Register
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="tab-pane" id="general" [ngClass]="{'active': formType == 'general'}" *ngIf="1 != 1">
      <form [formGroup]="generalForm" (ngSubmit)="generalFormSubmit()" id="general-register-form">
        <div class="register-form-field-grp panel-tab-section">
          <div class="row">
            <div class="col">
              <label class="form-label">First Name <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['first_name'].invalid && generalForm.controls['first_name'].touched || submitted) || gf.first_name.errors?.apiError, 'is-valid' : !(generalForm.controls['first_name'].invalid)}"
                formControlName="first_name" type="text" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['first_name'].invalid && generalForm.controls['first_name'].touched || submitted) || gf.first_name.errors?.apiError">
                <span *ngIf="gf.first_name.errors?.required">Please enter first name</span>
                <span *ngIf="gf.first_name.errors !== null && gf.first_name.errors">Please enter only alphabets</span>
                <span *ngIf="gf.first_name.errors?.apiError">{{gf.first_name.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">Last Name <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['last_name'].invalid && generalForm.controls['last_name'].touched || submitted) || gf.last_name.errors?.apiError, 'is-valid' : !(generalForm.controls['last_name'].invalid)}"
                formControlName="last_name" type="text" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['last_name'].invalid && generalForm.controls['last_name'].touched || submitted) || gf.last_name.errors?.apiError">
                <span *ngIf="gf.last_name.errors?.required">Please enter last name</span>
                <span *ngIf="gf.last_name.errors !== null && gf.last_name.errors">Please enter only alphabets</span>
                <span *ngIf="gf.last_name.errors?.apiError">{{gf.last_name.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Business Address Line 1 <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['address_line_1'].invalid && generalForm.controls['address_line_1'].touched || submitted) || gf.address_line_1.errors?.apiError, 'is-valid' : !(generalForm.controls['address_line_1'].invalid)}"
                formControlName="address_line_1" type="text" [options]="options" ngx-google-places-autocomplete
                #placesRef="ngx-places" placeholder="" (onAddressChange)="handleGeneralAddressChange($event)" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['address_line_1'].invalid && generalForm.controls['address_line_1'].touched || submitted) || gf.address_line_1.errors?.apiError || gf.address_line_1.errors?.streetRequired">
                <span *ngIf="gf.address_line_1.errors?.required">Please enter business address line
                  1</span>
                <span *ngIf="gf.address_line_1.errors?.apiError">{{gf.address_line_1.errors?.apiError}}</span>
                <span
                  *ngIf="gf.address_line_1.errors?.streetRequired">{{gf.address_line_1.errors?.streetRequired}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Business Address Line 2</label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['address_line_2'].invalid && generalForm.controls['address_line_2'].touched || submitted) || gf.address_line_2.errors?.apiError, 'is-valid' : !(generalForm.controls['address_line_2'].invalid)}"
                formControlName="address_line_2" type="text" placeholder="" (keydown.enter)="$event.preventDefault()">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Suburb <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['suburb'].invalid && generalForm.controls['suburb'].touched || submitted) || gf.suburb.errors?.apiError, 'is-valid' : !(generalForm.controls['suburb'].invalid)}"
                formControlName="suburb" type="text" placeholder="" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['suburb'].invalid && generalForm.controls['suburb'].touched || submitted) || gf.suburb.errors?.apiError">
                <span *ngIf="gf.suburb.errors?.required">Please enter suburb</span>
                <span *ngIf="gf.suburb.errors?.apiError">{{gf.suburb.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">City <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['town_city'].invalid && generalForm.controls['town_city'].touched || submitted) || gf.town_city.errors?.apiError, 'is-valid' : !(generalForm.controls['town_city'].invalid)}"
                formControlName="town_city" type="text" placeholder="" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['town_city'].invalid && generalForm.controls['town_city'].touched || submitted) || gf.town_city.errors?.apiError">
                <span *ngIf="gf.town_city.errors?.required">Please enter city</span>
                <span *ngIf="gf.town_city.errors?.apiError">{{gf.town_city.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">State <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['state_region'].invalid && generalForm.controls['state_region'].touched || submitted) || gf.state_region.errors?.apiError, 'is-valid' : !(generalForm.controls['state_region'].invalid)}"
                formControlName="state_region" type="text" placeholder="" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['state_region'].invalid && generalForm.controls['state_region'].touched || submitted) || gf.state_region.errors?.apiError">
                <span *ngIf="gf.state_region.errors?.required">Please enter state</span>
                <span *ngIf="gf.state_region.errors?.apiError">{{gf.state_region.errors?.apiError}}</span>
              </span>
            </div>
            <div class="col">
              <label class="form-label">Postal Code <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['post_code'].invalid && generalForm.controls['post_code'].touched || submitted) || gf.post_code.errors?.apiError, 'is-valid' : !(generalForm.controls['post_code'].invalid)}"
                formControlName="post_code" type="text" placeholder="" numbersOnly maxlength="4" required
                (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['post_code'].invalid && generalForm.controls['post_code'].touched || submitted) || gf.post_code.errors?.apiError">
                <span *ngIf="gf.post_code.errors?.required">Please enter postal code</span>
                <span *ngIf="gf.post_code.errors?.pattern">Please enter valid postal code</span>
                <span *ngIf="gf.post_code.errors?.apiError">{{gf.post_code.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Country <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['country'].invalid && generalForm.controls['country'].touched || submitted) || gf.country.errors?.apiError, 'is-valid' : !(generalForm.controls['country'].invalid)}"
                formControlName="country" type="text" placeholder="" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['country'].invalid && tradeForm.controls['country'].touched || submitted) || gf.country.errors?.apiError">
                <span *ngIf="gf.country.errors?.required">Please enter country</span>
                <span *ngIf="gf.country.errors?.apiError">{{gf.country.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Mobile <span class="required-field">*</span></label>
              <input class="form-control" [imask]="{mask: '0000 000 000',lazy:false}" [unmask]="true"
                [ngClass]="{ 'is-invalid': (generalForm.controls['mobile'].invalid && generalForm.controls['mobile'].touched || submitted) || gf.mobile.errors?.apiError,
                'mobile-mask-class': generalForm.controls['mobile'].value, 'is-valid' : !(generalForm.controls['mobile'].invalid)}" formControlName="mobile" type="text" required
                (keydown.enter)="$event.preventDefault()" />
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['mobile'].invalid && generalForm.controls['mobile'].touched || submitted) || gf.mobile.errors?.apiError">
                <span *ngIf="gf.mobile.errors?.required">Please enter mobile</span>
                <span *ngIf="gf.mobile.errors?.apiError">{{gf.mobile.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Email <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['email'].invalid && generalForm.controls['email'].touched || submitted) || gf.email.errors?.apiError, 'is-valid' : !(generalForm.controls['email'].invalid)}"
                formControlName="email" type="text" required email (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['email'].invalid && generalForm.controls['email'].touched || submitted) || gf.email.errors?.apiError">
                <span *ngIf="gf.email.errors?.required">Please enter email</span>
                <span *ngIf="gf.email.errors?.email">Please enter a valid email address</span>
                <span *ngIf="gf.email.errors?.apiError">{{gf.email.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Password <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['password'].invalid && generalForm.controls['password'].touched || submitted) || gf.password.errors?.apiError, 'is-valid' : !(generalForm.controls['password'].invalid)}"
                formControlName="password" type="password" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['password'].invalid && generalForm.controls['password'].touched || submitted) || gf.password.errors?.apiError">
                <span *ngIf="gf.password.errors?.required">Please enter password</span>
                <span *ngIf="gf.password.errors?.apiError">{{gf.password.errors?.apiError}}</span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Confirm Password <span class="required-field">*</span></label>
              <input class="form-control"
                [ngClass]="{ 'is-invalid': (generalForm.controls['confirm_password'].invalid && generalForm.controls['confirm_password'].touched || submitted) || gf.confirm_password.errors?.apiError, 'is-valid' : !(generalForm.controls['confirm_password'].invalid)}"
                formControlName="confirm_password" type="password" required (keydown.enter)="$event.preventDefault()">
              <span class="invalid-feedback" role="alert"
                *ngIf="(generalForm.controls['confirm_password'].invalid && generalForm.controls['confirm_password'].touched || submitted) || gf.confirm_password.errors?.apiError">
                <span *ngIf="gf.confirm_password.errors?.required">Please enter confirm password</span>
                <span *ngIf="gf.confirm_password.errors?.passwordNotMatch">Password and Confirm Password
                  does not
                  match</span>
                <span *ngIf="gf.confirm_password.errors?.apiError">{{gf.confirm_password.errors?.apiError}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="row form-submit-btn">
          <div class="col">
            <button type="submit" class="btn grey-bordered-btn general-register-btn">
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
