import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-favourite-success-modal',
  templateUrl: './product-favourite-success-modal.component.html',
  styleUrls: ['./product-favourite-success-modal.component.css']
})
export class ProductFavouriteSuccessModalComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  @Input() message!: string;
  /* ################### Variable Declaration End ################### */

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
  }
  /************************************
   * Life cycle hooks end
   ************************************/
}
