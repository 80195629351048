import {FrontendUserAddressModel} from "@coremodels";

export class UserModel {
  id?: number;
  first_name!: string;
  last_name!: string;
  address?: string;
  address_line_1?: string;
  address_line_2?: string;
  suburb?: string;
  town_city?: string;
  state_region?: string;
  country?: string;
  mobile!: string;
  email!: string;
  password?: string;
  post_code?: any;
  abn?: string;
  company_name?: string;
  company_address?: string;
  website?: string;
  business_phone?: string;
  business_role?: string;
  business_role_id?: number;
  job_title?: string;
  role?: string;
  company_id?: number;
  status?: string;
  profile_picture?: any;
  color_code?: any;
  frontendUserAddresses?: FrontendUserAddressModel[];
  trade_type_id?: number;
  company?: any;
  assigned_to_admin?: any;
  frontendUserCompanyDefaultAddresses?: any;
  bpay_biller_code?: any;
  bpay_reference?: any;
}
