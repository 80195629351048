import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {CoreModule} from "./core/core.module";
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {NgbAccordionModule, NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {ToastrModule} from 'ngx-toastr';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {VerifyAccountComponent} from './auth/verify-account/verify-account.component';
import {IMaskModule} from "angular-imask";
import {AlertService} from "@coreservices";
import {TabsModule} from "ngx-bootstrap/tabs";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {HttpClientModule} from "@angular/common/http";
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NgxOtpInputModule} from "ngx-otp-input";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {SharedModule} from "./shared/shared.module";
import {CarrierJobThankYouModalComponent} from './home/modal/carrier-job-thank-you-modal/carrier-job-thank-you-modal.component';
import {CarrierJobDeclineModalComponent} from './home/modal/carrier-job-decline-modal/carrier-job-decline-modal.component';
import {NgOptimizedImage, provideImageKitLoader, IMAGE_LOADER, ImageLoaderConfig} from "@angular/common";
import {FilterByFlooringComponent} from './layout/mega-menu/filter-by-flooring/filter-by-flooring.component';
import {FilterByWallingComponent} from './layout/mega-menu/filter-by-walling/filter-by-walling.component';
import {MegaMenuFlooringComponent} from "./layout/mega-menu-flooring/mega-menu-flooring.component";
import {MegaMenuWallingComponent} from "./layout/mega-menu-walling/mega-menu-walling.component";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MegaMenuFlooringComponent,
    MegaMenuWallingComponent,
    RightSidebarComponent,
    LoginComponent,
    RegisterComponent,
    VerifyAccountComponent,
    CarrierJobThankYouModalComponent,
    CarrierJobDeclineModalComponent,
    FilterByFlooringComponent,
    FilterByWallingComponent,
  ],

  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgOptimizedImage,
    ToastrModule.forRoot(
      {maxOpened: 1, autoDismiss: true, preventDuplicates: true,}
    ),
    BsDropdownModule,
    NgbDropdownModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    IMaskModule,
    GooglePlaceModule,
    TooltipModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot({}),
    NgxOtpInputModule,
    SocialLoginModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    AlertService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleAppId
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    Meta,
    provideImageKitLoader(environment.imageKitEndpoint)
    // {
    //   provide: IMAGE_LOADER,
    //   useValue: (config: ImageLoaderConfig) => {
    //     let imageUrl = environment.gcImageHubEndPoint + config.src;
    //     // Add width parameter if provided
    //     if (config.width) {
    //       imageUrl += `?tr=w-${config.width}`;
    //     }
    //     return imageUrl;
    //   },
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
