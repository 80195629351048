import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';


import {AuthService} from '@coreservices';
import {isPlatformBrowser, isPlatformServer} from "@angular/common";


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService  {

    constructor(
        public auth: AuthService,
        public router: Router,
        public activeRoute: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.data && route.data.parent && route.parent && route.parent.routeConfig) {
            return true;
        } else {
            this.router.navigate(["/404"]);
            return false;
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (isPlatformBrowser(this.platformId)) {
            if (!this.auth.isUserAuthenticated()) {
                localStorage.setItem("is_open_login_sidebar", "true");
                //this.router.navigate(['login']);
                localStorage.setItem("redirect_url", state.url);
                this.router.navigate(['login']);
                return false;
            }
            return true;
        }

        return true;
    }
}
