<div class="commitment-thank">
    <div class="modal-body">
        <div class="donate-modal-container">
            <div class="donate-image">
                <button type="button" class="btn-close" aria-label="Close" (click)="close()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>
            <h3 class="thank-you-text">Delivery Job Declined</h3>
            <p class="say-thank-you-text">
                <!-- Thanks for goods despatch confirmation! -->
                Our dispatching team has been notified. No further action is required. 
            </p>
        </div>
    </div>
</div>