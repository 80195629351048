import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wa-sample-request-restriction-modal',
  templateUrl: './wa-sample-request-restriction-modal.component.html',
  styleUrls: ['./wa-sample-request-restriction-modal.component.css', '../sample-order-confirmation/sample-order-confirmation.component.css']
})
export class WaSampleRequestRestrictionModalComponent {
  constructor(public ngbActiveModal: NgbActiveModal,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }
  openContactEmail() {
    if (isPlatformBrowser(this.platformId)) {
      let url = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hi@gatherco.com.au&su=Your+Subject+here&ui=2&tf=1&pli=1'
      window.open(url, 'sharer', 'toolbar=0,status=0,width=648,height=395');
    }
  }
  closePopup(){
    this.ngbActiveModal.close(true)
  }
}
