import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FrontendUserVerificationCodeModel, ResponseModel, UserModel} from '@coremodels';
import {AlertService, AuthService, SubscribersService} from '@coreservices';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {ThemeOptions} from 'src/app/core/theme-options';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css', '../auth.css']
})
export class VerifyAccountComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  isLoading!: false;
  verificationForm!: UntypedFormGroup
  frontendUserVerificationCodeModel: any = FrontendUserVerificationCodeModel;
  submitted = false;
  tradeForm: any;
  color_code: any;

  /* ################### Variable Declaration End ################### */

  constructor(private subscriberService: SubscribersService,
              private globals: ThemeOptions,
              private _formBuilder: UntypedFormBuilder,
              private authenticationService: AuthService,
              private alertService: AlertService) {

    this.subscriberService.verificationTabForm.subscribe((options) => {
      this.tradeForm = options;
    });
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    this.verificationForm = this._formBuilder.group({
      otp: ["", Validators.required]
    });
    this.verificationForm.reset();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  get vf() {
    return this.verificationForm?.controls;
  }

  tradeFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.tradeForm.invalid) {
      this.tradeForm.markAsTouched();
      this.tradeForm.markAsDirty();
      return;
    }

    this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    var signUpForm: UserModel = {
      abn: this.tradeForm.abn,
      company_name: this.tradeForm.company_name,
      address_line_1: this.tradeForm.address_line_1,
      address_line_2: this.tradeForm.address_line_2,
      suburb: this.tradeForm.suburb,
      town_city: this.tradeForm.town_city,
      state_region: this.tradeForm.state_region,
      country: this.tradeForm.country,
      post_code: this.tradeForm.post_code,
      website: this.tradeForm.website,
      first_name: this.tradeForm.first_name,
      last_name: this.tradeForm.last_name,
      business_role_id: this.tradeForm.business_role_id,
      job_title: this.tradeForm.job_title,
      mobile: this.tradeForm.mobile,
      email: this.tradeForm.email,
      password: this.tradeForm.password,
      role: Constants.USER_TYPE_TRADE,
      trade_type_id: this.tradeForm.trade_type_id,
      color_code: this.color_code,
      assigned_to_admin: this.tradeForm.assigned_to_admin
    }

    this.authenticationService.signUp(signUpForm).subscribe(
      (userModel: UserModel) => {
        this.submitted = false;
      }, (error: any) => {
        if (error.status == 422) {
          let self = this;
          if (error.error.data.length > 0) {
            Object.keys(error.error.data).forEach(function (key, data) {
              self.tradeForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
            });
          }
        }
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
  }

  onSubmit() {
    this.submitted = true
    this.verificationForm.markAllAsTouched();

    // stop here if form is invalid
    if (this.verificationForm.invalid) {
      return false;
    }

    this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

    var code = this.verificationForm.get("otp")?.value;
    this.frontendUserVerificationCodeModel = {
      email: localStorage.getItem("email"),
      verification_code: code
    }

    this.authenticationService.verifiyCode(this.frontendUserVerificationCodeModel).subscribe(
      (response: any) => {
        if (response.is_two_factor_verified == Constants.IS_TWO_FACTOR_VERIFIED) {
          this.submitted = false;
          this.tradeFormSubmit()
          this.alertService.success(Constants.SUCCESS_USER_REGISTERED, 'Success');
          this.verificationForm.reset();

          this.globals.rightSidebar = false;
          this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
          this.globals.rightSidebarAccountLoader = false;
          this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

        }
      },
      (error: any) => {
        if (error.status == 422) {
          let self = this;
          if (error.error.data.length > 0) {
            Object.keys(error.error.data).forEach(function (key, data) {
              self.verificationForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
            });
          }
        } else if (error.status == 404 || error.status == 400) {
          this.alertService.error(error.error.data.message, 'Error');
        }
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });

    return;
  }

  cancel() {
    // Open registration form
    this.globals.registrationForm = 'trade';
    this.subscriberService.registrationFormToggle(this.globals.registrationForm);

    // Pass trade form data
    this.subscriberService.registrationFormToggleWithData(this.tradeForm);
  }

  resendCode() {
    this.frontendUserVerificationCodeModel = {
      email: this.tradeForm.email
    }

    this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

    this.authenticationService.reSendVerificationcode(this.frontendUserVerificationCodeModel).subscribe(
      (res) => {
        localStorage.setItem("email", this.frontendUserVerificationCodeModel.email);
        this.submitted = false;
        this.verificationForm.reset();
        this.alertService.success(Constants.SUCCESS_VERIFICATION_CODE, 'Success');

        // Hide loader
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

        // Open verification sidebar
        this.globals.registrationForm = 'otp-verification';
        this.subscriberService.registrationFormToggle(this.globals.registrationForm);

      },
      (error: any) => {
        if (error.status == 422) {
          let self = this;
          if (error.error.data.length > 0) {
            Object.keys(error.error.data).forEach(function (key, data) {
              self.tradeForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
            });
          }
        }
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
  }

  /************************************
   * Other functions end
   ************************************/
}
