import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-mega-menu-walling',
  templateUrl: './mega-menu-walling.component.html',
  styleUrls: ['./mega-menu-walling.component.css']
})
export class MegaMenuWallingComponent {
 /* ################### Variable Declaration Start ################### */
  @Output("redirectFromMegaMenu") redirectFromMegaMenu: EventEmitter<any> = new EventEmitter();

   /* ################### Variable Declaration End ################### */

  /************************************
  * Life cycle hooks start
  ************************************/
  ngOnInit() {
  }

  /************************************
  * Life cycle hooks End
  ************************************/

  /************************************
  * Redirect functions start
  ************************************/
  redirectFromChildMegaMenu($event: any) {
    this.redirectFromMegaMenu.emit($event);
  }

  /************************************
  * Redirect functions end
  ************************************/

}
