import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class JsonLdService {
    private renderer: Renderer2;
    static scriptType = 'application/json+ld';
    static websiteSchema = (url?: string, name?: string) => {
        return {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: url || 'https:\/\/google.com',
            name: name || 'Google',
            "sameAs": ["https://facebook.com/google",
                "https://instagram.com/google",
                "https://twitter.com/google"]
        };
    };

    static orgSchema = () => ({
        '@context':
            'https://schema.org',
        '@type':
            'Organization',
        url:
            'https://google.com',
        name:
            'Google',
        contactPoint: {
            '@type':
                'ContactPoint',
            telephone:
                '01293019413',
            contactType:
                'Customer service'
        }
    });

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    removeStructuredData(): void {
        const els: any = [];
        ['structured-data', 'structured-data-org'].forEach(c => {
            els.push(...Array.from(this._document.head.getElementsByClassName(c)));
        });
        els.forEach((el: any) => this._document.head.removeChild(el));
    }
    
    insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
        var script: any = '';
        let shouldAppend = false;
        if (this._document.head.getElementsByClassName(className).length) {
            script = this._document.head.getElementsByClassName(className)[0];
        } else {
            //script = this._document.createElement('script');
            script = this.renderer.createElement('script');
            shouldAppend = true;
        }
        script.setAttribute('class', className);
        if (script) {
            script.type = JsonLdService.scriptType;
            script.text = JSON.stringify(schema);
        }
        if (shouldAppend) {
            this._document.head.appendChild(script);
        }
    }
}
