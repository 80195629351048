import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {PaginationModel} from '../models/pagination.model';
import {RequestParamModel} from '../models/request-params.model';
import {BaseService} from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  public API_CONSTANT = 'product-resource';
  public API_CONSTANT_FOR_CATEGORY = 'product-resource-category';
  public API_CONSTANT_FOR_SUBCATEGORY = 'product-resource-sub-category';

  constructor(private baseService: BaseService) {
  }

  getResourceCategory(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.PRODUCT_RESOURCE_CATEGORY_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const categoryList = new PaginationModel<any>();
        const headers = response.headers;
        categoryList.setHeaders(headers);
        categoryList.data = response.body.data as any[];
        return categoryList;
      }, (error: any) => {
        return error;
      }))
  }

  getResource(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.PRODUCT_RESOURCE_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const categoryList = new PaginationModel<any>();
        const headers = response.headers;
        categoryList.setHeaders(headers);
        categoryList.data = response.body.data as any[];
        return categoryList;
      }, (error: any) => {
        return error;
      }))
  }


  getResourceSubCategory(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.PRODUCT_RESOURCE_SUB_CATEGORY_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const categoryList = new PaginationModel<any>();
        const headers = response.headers;
        categoryList.setHeaders(headers);
        categoryList.data = response.body.data as any[];
        return categoryList;
      }, (error: any) => {
        return error;
      }))
  }

  downloadResource(body: any) {
    return this.baseService.getDownloadFileContent(ApiConstants.PRODUCT_RESOURCE_DOWNLOAD_URL + `${body.id}`);
  }

}
