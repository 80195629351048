import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RequestParamModel} from '../models/request-params.model';
import {BaseService} from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})

export class FAQService {

  constructor(
    public baseService: BaseService
  ) {
  }

  getFaqList(requestParamModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.FAQ_LIST_URL, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      })
    )
  }

}
