<div class="modal-header">
  <h3 class="modal-title">
    Favourites
  </h3>
</div>
<div class="modal-body">
  <div class="board-project-list">
    <ul>
      <li>
        <div class="product-thumb-preview">
          <h4 class="not-found">{{ message }}</h4>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <div class="footer-btn-grp">
    <button class="btn grey-bordered-btn create-board" (click)="activeModal.close();">
      Close
    </button>
  </div>
</div>