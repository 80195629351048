import { Injectable } from '@angular/core';
import { RequestParamModel } from '../models';
import { map } from 'rxjs/operators';
import { BaseService } from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private baseService: BaseService) { }

  // get setting details
  getSettingDetail(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.SETTINGS_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  // get all user list
  getSettingDetailView(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.SETTING_DETAIL_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  save(requestParamModel: any): any {
    return this.baseService.post(ApiConstants.SETTING_UPDATE_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        response.body;
      }, (err: any) => {
        return err;
      }));
  }

  getDownloadEligibilityCriteria(): any {
    return this.baseService.getWithoutToken(ApiConstants.SETTING_DOWNLOAD_ELIGIBILITY_CRITERIA_URL)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }
}
