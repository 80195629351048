import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {FamilyModel} from '../models/family.model';
import {PaginationModel} from '../models/pagination.model';
import {RequestParamModel} from '../models/request-params.model';
import {BaseService} from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  constructor(private baseService: BaseService) {
  }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.FAMILY_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<FamilyModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as FamilyModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getFamily(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.FAMILY_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<FamilyModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as FamilyModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }
}
