import {HttpErrorResponse} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {
  AccountModel,
  FrontendUserVerificationCodeModel,
  PaginationModel,
  RequestParamModel,
  TradeTypeModel,
  UserModel
} from '@coremodels';

import {AccountsService, AlertService, AuthService, HelperService, SettingService} from '@coreservices';

import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {SubscribersService} from 'src/app/core/services/subscribers.service';
import {ThemeOptions} from 'src/app/core/theme-options';
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {GoogleAddressModel} from 'src/app/core/models/google-address.model';
import {environment} from "src/environments/environment";
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css', '../auth.css']
})
export class RegisterComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  isLoading!: false;
  formType: string = 'trade';
  submitted = false;
  tradeForm!: UntypedFormGroup;
  generalForm!: UntypedFormGroup;
  @ViewChild("placesRef") placesRef?: GooglePlaceDirective;
  @ViewChild("generalPlacesRef") generalPlacesRef?: GooglePlaceDirective;
  tradeLogo!: string | null;
  options = {
    componentRestrictions: {country: 'AU'},
    strictBounds: true,
    fields: ['ALL'],
    types: ['address'],
  };
  tradeType!: TradeTypeModel[];
  selectedTradeType = 'Select';
  selectedBusinessRoleType = 'Select';
  isShowTradeTypeDropdown!: boolean;
  isShowFormOtherField!: boolean;
  businessRole: any;
  rightDrawerToggle: boolean = false
  verificationCodeForm: any = FrontendUserVerificationCodeModel
  tradeFormData: any;
  color_code: any;
  public userDetailsDisable: boolean = false;

  /* ################### Variable Declaration End ################### */

  constructor(
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private changeDetectionRef: ChangeDetectorRef,
    private authenticationService: AuthService,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private accountServices: AccountsService,
    public helperService: HelperService,
    private settingServices: SettingService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.subscriberService.registrationTabForm.subscribe((options) => {
      this.globals.registrationForm = options;
      this.formType = options;
      this.submitted = false;
      this.generalForm.reset();

      this.tradeForm.controls['trade_type_id'].enable();
      this.tradeForm.controls['company_name'].enable();
      this.tradeForm.controls['address_line_1'].enable();
      this.tradeForm.controls['address_line_2'].enable();
      this.tradeForm.controls['suburb'].enable();
      this.tradeForm.controls['town_city'].enable();
      this.tradeForm.controls['state_region'].enable();
      this.tradeForm.controls['country'].enable();
      this.tradeForm.controls['post_code'].enable();
      this.tradeForm.controls['website'].enable();
      this.tradeLogo = '';
      this.selectedTradeType = 'Select';
      this.tradeForm.reset();
      this.tradeForm.markAsUntouched();
      this.changeDetectionRef.markForCheck();
    });

    this.subscriberService.registrationTabFormWithData.subscribe((data) => {
      this.tradeFormData = data
      this.tradeForm.controls['abn'].setValue(this.tradeFormData.abn)
      this.tradeForm.controls['address_line_1'].setValue(this.tradeFormData.address_line_1)
      this.tradeForm.controls['address_line_2'].setValue(this.tradeFormData.address_line_2)
      this.tradeForm.controls['assigned_to_admin'].setValue(this.tradeFormData.assigned_to_admin)
      this.tradeForm.controls['business_role'].setValue(this.tradeFormData.business_role_id)
      this.tradeForm.controls['company_name'].setValue(this.tradeFormData.company_name)
      this.tradeForm.controls['country'].setValue(this.tradeFormData.country)
      this.tradeForm.controls['email'].setValue(this.tradeFormData.email)
      this.tradeForm.controls['post_code'].setValue(this.tradeFormData.post_code)
      this.tradeForm.controls['state_region'].setValue(this.tradeFormData.state_region)
      this.tradeForm.controls['suburb'].setValue(this.tradeFormData.suburb)
      this.tradeForm.controls['town_city'].setValue(this.tradeFormData.town_city)
      this.tradeForm.controls['trade_type_id'].setValue(this.tradeFormData.trade_type_id)
      this.tradeForm.controls['website'].setValue(this.tradeFormData.website)
      this.tradeForm.controls['first_name'].setValue(this.tradeFormData.first_name)
      this.tradeForm.controls['last_name'].setValue(this.tradeFormData.last_name)
      this.tradeForm.controls['job_title'].setValue(this.tradeFormData.job_title)
      this.tradeForm.controls['mobile'].setValue(this.tradeFormData.mobile)
      this.tradeForm.controls['email'].setValue(this.tradeFormData.email)
      this.tradeForm.controls['password'].setValue(this.tradeFormData.password)
      this.changeDetectionRef.detectChanges();
      this.tradeForm.markAsUntouched();
    });

    this.tradeLogo = '';
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    this.isShowFormOtherField = false;
    this.generalForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      job_title: '',
      address_line_1: ['', [Validators.required]],
      address_line_2: '',
      suburb: ['', Validators.required],
      town_city: ['', Validators.required],
      state_region: ['', Validators.required],
      country: ['', Validators.required],
      post_code: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, this.passwordMatcher.bind(this)]],
    });

    let urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.tradeForm = this.fb.group({
      trade_type_id: ['', [Validators.required]],
      abn: ['', [Validators.required, this.abnValidator.bind(this)]],
      assigned_to_admin: [''],
      company_name: ['', [Validators.required]],
      address_line_1: ['', [Validators.required, this.addressValidator.bind(this)]],
      address_line_2: '',
      job_title: '',
      suburb: ['', Validators.required],
      town_city: ['', Validators.required],
      state_region: ['', Validators.required],
      post_code: ['', [Validators.required]],
      country: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern(urlRegex)]],
      first_name: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern(/^[a-zA-Z_'-]*$/)]],
      last_name: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern(/^[a-zA-Z_'-]*$/)]],
      business_role: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(10), this.mobileValidator.bind(this)]],
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, this.passwordTradeMatcher.bind(this)]],
      confirm_email: ['', [Validators.required, Validators.pattern(emailRegex), this.confirmMailTradeMatcher.bind(this)]]
    });

    this.tf.email.valueChanges.subscribe(() => this.tf.confirm_email.updateValueAndValidity());
    this.tf.password.valueChanges.subscribe(() => this.tf.confirm_password.updateValueAndValidity());

    this.getTradeTypeList();
    this.getBusinessList();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  get gf() {
    return this.generalForm.controls;
  }

  get tf() {
    return this.tradeForm.controls;
  }

  private passwordMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.generalForm && (control.value !== this.generalForm.controls.password.value)) {
      return {passwordNotMatch: true};
    }
    return {};
  }

  private passwordTradeMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.tradeForm && (control.value !== this.tradeForm.controls.password.value)) {
      return {passwordNotMatch: true};
    }
    return {};
  }

  private confirmMailTradeMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.tradeForm && (control.value?.toLowerCase() !== this.tradeForm.controls.email.value?.toLowerCase())) {
      return {confirmEmailNotMatch: true};
    }
    return {};
  }

  private mobileValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.tradeForm && control.value !== '' && control.value !== null) {
      let $mobileNumber = control.value;
      if ($mobileNumber.substring(0, 2) == '04') {
        return {};
      }
    }
    return {mobileNotValid: true};
  }

  private addressValidator(control: UntypedFormControl): { [s: string]: boolean } {
    let addressRegex = Constants.PO_BOX_ADDRESS_REGEX;

    if (this.tradeForm && control.value !== '' && control.value !== null) {
      let $addressLine1 = control.value;
      if (!addressRegex.test($addressLine1)) {
        return {};
      }
    }
    return {addressNotValid: true};
  }

  private abnValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.tradeForm && control.value !== '' && control.value !== null) {
      this.tradeLogo = '';
      this.isShowTradeTypeDropdown = false;
      this.tradeForm.controls['trade_type_id'].setValue('');
      this.tradeForm.controls['trade_type_id'].enable();
      this.tradeForm.controls['assigned_to_admin'].setValue('');
      this.tradeForm.controls['assigned_to_admin'].enable();
      this.tradeForm.controls['company_name'].setValue('');
      this.tradeForm.controls['company_name'].enable();
      this.tradeForm.controls['address_line_1'].setValue('');
      this.tradeForm.controls['address_line_1'].enable();
      this.tradeForm.controls['address_line_2'].setValue('');
      this.tradeForm.controls['address_line_2'].enable();
      this.tradeForm.controls['suburb'].setValue('');
      this.tradeForm.controls['suburb'].enable();
      this.tradeForm.controls['town_city'].setValue('');
      this.tradeForm.controls['town_city'].enable();
      this.tradeForm.controls['state_region'].setValue('');
      this.tradeForm.controls['state_region'].enable();
      this.tradeForm.controls['country'].setValue('');
      this.tradeForm.controls['country'].enable();
      this.tradeForm.controls['post_code'].setValue('');
      this.tradeForm.controls['post_code'].enable();
      this.tradeForm.controls['website'].setValue('');
      this.tradeForm.controls['website'].enable();
      this.userDetailsDisable = false;
      let $abn = control.value;
      const $weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

      //remove spaces from string
      $abn = $abn.replace(/\s+/g, '');
      // strip anything other than digits
      $abn = $abn.replace("/[^\d]/", "");

      // check length is 11 digits
      if ($abn.length == 11) {
        // apply ato check method
        let $sum = 0;
        $weights.forEach(function (value, i) {
          let $digit = $abn[i] - (i ? 0 : 1);
          $sum += value * $digit;
        });
        if (($sum % 89) == 0) {
          this.isShowFormOtherField = true;
          this.globals.rightSidebarAccountLoader = false;
          this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
          var accountModel: any = {
            abn: $abn
          };
          this.accountServices.getAccountByABN(accountModel).subscribe((account: AccountModel) => {
            if (account != undefined && account.id != undefined && account.id != null) {
              let s3AccountLogoURL = Constants.S3_ACCOUNT_LOGO_IMAGE_KIT_URL
              let logoName = account.logo || '';
              if (logoName != '') {
                this.tradeLogo = s3AccountLogoURL + '/' + logoName;
              }
              if (account.company_name !== '' && account.company_name !== null) {
                this.userDetailsDisable = true;
                this.tradeForm.controls['company_name'].disable();
                this.tradeForm.controls['company_name'].setValue(account.company_name);
              }
              if (account && account.trade_type_id) {
                this.isShowTradeTypeDropdown = true;
                this.tradeType.forEach((ele: any) => {
                  if (ele.id === account.trade_type_id) {
                    this.changeTradeType(ele);
                  }
                });
              }
              if (account && account.frontendUsers && account.frontendUsers.length > 0) {
                this.tradeForm.controls['assigned_to_admin'].setValue(account.frontendUsers[0].assigned_to_admin);
              }
              if (account.companyAddresses && account.companyAddresses.length > 0) {
                this.tradeForm.controls['address_line_1'].setValue(account.companyAddresses[account.companyAddresses.length - 1].address_line_1);
                this.tradeForm.controls['address_line_1'].enable();
                this.tradeForm.controls['address_line_2'].setValue(account.companyAddresses[account.companyAddresses.length - 1].address_line_2);
                this.tradeForm.controls['address_line_2'].enable();
                this.tradeForm.controls['suburb'].setValue(account.companyAddresses[account.companyAddresses.length - 1].suburb);
                this.tradeForm.controls['suburb'].enable();
                this.tradeForm.controls['town_city'].setValue(account.companyAddresses[account.companyAddresses.length - 1].town_city);
                this.tradeForm.controls['town_city'].enable();
                this.tradeForm.controls['state_region'].setValue(account.companyAddresses[account.companyAddresses.length - 1].state_region);
                this.tradeForm.controls['state_region'].enable();
                this.tradeForm.controls['country'].setValue(account.companyAddresses[account.companyAddresses.length - 1].country);
                this.tradeForm.controls['country'].enable();
                this.tradeForm.controls['post_code'].setValue(+account.companyAddresses[account.companyAddresses.length - 1].post_code);
                this.tradeForm.controls['post_code'].enable();
              }
              this.tradeForm.controls['website'].setValue(account.website);
              this.tradeForm.controls['website'].enable();
              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            } else {
              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            }
          }, (error: HttpErrorResponse) => {
            if (error.status == 422) {
              let self = this;
              if (error.error.data.length > 0) {
                Object.keys(error.error.data).forEach(function (key, data) {
                  self.tradeForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
                });
              }
              this.globals.rightSidebarAccountLoader = !false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            }
          });
          return {};
        }
      }
      this.isShowFormOtherField = false;
      return {abnNotValid: true};
    } else if (!control.value) {
      // this.tradeLogo = '';
      // this.isShowTradeTypeDropdown = false;
      // this.selectedTradeType = 'Select';
      // this.tradeForm?.controls['trade_type_id'].setValue('');
      // this.tradeForm?.controls['trade_type_id'].enable();
      // this.tradeForm?.controls['assigned_to_admin'].setValue('');
      // this.tradeForm?.controls['assigned_to_admin'].enable();
      // this.tradeForm?.controls['company_name'].setValue('');
      // this.tradeForm?.controls['company_name'].enable();
      // this.tradeForm?.controls['address_line_1'].setValue('');
      // this.tradeForm?.controls['address_line_1'].enable();
      // this.tradeForm?.controls['address_line_2'].setValue('');
      // this.tradeForm?.controls['address_line_2'].enable();
      // this.tradeForm?.controls['suburb'].setValue('');
      // this.tradeForm?.controls['suburb'].enable();
      // this.tradeForm?.controls['town_city'].setValue('');
      // this.tradeForm?.controls['town_city'].enable();
      // this.tradeForm?.controls['state_region'].setValue('');
      // this.tradeForm?.controls['state_region'].enable();
      // this.tradeForm?.controls['country'].setValue('');
      // this.tradeForm?.controls['country'].enable();
      // this.tradeForm?.controls['post_code'].setValue('');
      // this.tradeForm?.controls['post_code'].enable();
      // this.tradeForm?.controls['website'].setValue('');
      // this.tradeForm?.controls['website'].enable();
      // this.userDetailsDisable = false;
    }
    this.isShowFormOtherField = false;
    return {abnNotValid: true};
  }

  changeTradeType(trade: TradeTypeModel) {
    this.tradeForm.controls['trade_type_id'].setValue(trade.id);
    this.selectedTradeType = trade.name;
  }

  getTradeTypeList() {
    let requestParamModel = new RequestParamModel();
    delete requestParamModel.pageSize;
    requestParamModel.fields = ['id', 'name'];
    requestParamModel.sortChange('name', 'asc');
    this.accountServices.getTradeTypeLists(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      requestParamModel.update<any>(dataList);
      this.tradeType = dataList.data;
      this.changeDetectionRef.markForCheck();
    }, (error: HttpErrorResponse) => {
      this.helperService.handleError(error);
    });
  }

  businessList(trade1: any) {
    this.tradeForm.controls['business_role'].setValue(trade1.id);
    this.selectedBusinessRoleType = trade1.name;
  }

  getBusinessList() {
    let requestParamModel = new RequestParamModel();
    delete requestParamModel.pageSize;
    requestParamModel.fields = ['id', 'name'];
    requestParamModel.sortChange('name', 'asc');
    this.accountServices.getBusinessRoleList(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      requestParamModel.update<any>(dataList);
      this.businessRole = dataList.data;
      this.changeDetectionRef.markForCheck();
    }, (error: HttpErrorResponse) => {
      this.helperService.handleError(error);
    });
  }

  generalFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.generalForm.invalid) {
      return;
    }

    this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

    var signUpForm: UserModel = {
      first_name: this.gf.first_name.value,
      last_name: this.gf.last_name.value,
      address_line_1: this.gf.address_line_1.value,
      address_line_2: this.gf.address_line_2.value,
      suburb: this.gf.suburb.value,
      town_city: this.gf.town_city.value,
      state_region: this.gf.state_region.value,
      country: this.gf.country.value,
      post_code: this.gf.post_code.value,
      mobile: this.gf.mobile.value,
      email: this.gf.email.value,
      password: this.gf.password.value,
      role: Constants.USER_TYPE_GENERAL
    }


    this.authenticationService.signUp(signUpForm).subscribe(
      (userModel: UserModel) => {
        this.submitted = false;
        this.generalForm.reset();
        this.selectedTradeType = 'Select';
        this.alertService.success(Constants.SUCCESS_USER_REGISTERED, 'Success');
        this.globals.rightSidebar = false;
        this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
        this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      }, (error: any) => {
        this.submitted = false;
        this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
        if (error.status == 422) {
          let self = this;
          if (error.error.data.length > 0) {
            Object.keys(error.error.data).forEach(function (key, data) {
              self.generalForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
            });
          }
        }
      });
  }

  tradeFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.tradeForm.invalid) {
      this.tradeForm.markAsTouched();
      this.tradeForm.markAsDirty();
      return;
    }

    // Display loader
    this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

    // Pass trade form data
    var signUpForm: UserModel = {
      abn: this.tf.abn.value,
      company_name: this.tf.company_name.value,
      address_line_1: this.tf.address_line_1.value,
      address_line_2: this.tf.address_line_2.value,
      suburb: this.tf.suburb.value,
      town_city: this.tf.town_city.value,
      state_region: this.tf.state_region.value,
      country: this.tf.country.value,
      post_code: this.tf.post_code.value,
      website: this.tf.website.value,
      first_name: this.tf.first_name.value,
      last_name: this.tf.last_name.value,
      business_role_id: this.tf.business_role.value,
      job_title: this.tf.job_title.value,
      mobile: this.tf.mobile.value,
      email: this.tf.email.value,
      password: this.tf.password.value,
      role: Constants.USER_TYPE_TRADE,
      trade_type_id: this.tf.trade_type_id.value,
      assigned_to_admin: this.tf.assigned_to_admin.value
    }

    // Pass register form
    this.verificationCodeForm = {
      email: this.tf.email.value
    }

    this.authenticationService.sendVerificationcode(this.verificationCodeForm).subscribe(
      (res) => {
        localStorage.setItem("email", this.verificationCodeForm.email);
        this.submitted = false;
        this.generalForm.reset();
        this.alertService.success(Constants.SUCCESS_VERIFICATION_CODE, 'Success');

        // Hide loader
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

        // Open verification sidebar
        this.globals.registrationForm = 'otp-verification';
        this.subscriberService.registrationFormToggle(this.globals.registrationForm);
        this.subscriberService.verificationFormToggle(signUpForm);

      },
      (error: any) => {
        if (error.status == 422) {
          let self = this;
          if (error.error.data.length > 0) {
            Object.keys(error.error.data).forEach(function (key, data) {
              self.tradeForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
            });
          }
        }
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
  }

  handleCompanyAddressChange(address: Address) {
    this.tradeForm.controls['address_line_1'].setValue('');
    this.tradeForm.controls['address_line_2'].setValue('');
    this.tradeForm.controls['post_code'].setValue('');
    this.tradeForm.controls['suburb'].setValue('');
    this.tradeForm.controls['town_city'].setValue('');
    this.tradeForm.controls['state_region'].setValue('');
    this.tradeForm.controls['country'].setValue('');
    if (address !== undefined && address.formatted_address !== undefined) {
      let addressObject = this.helperService.addressHandler(address) as GoogleAddressModel;
      if (addressObject) {
        this.tradeForm.controls['address_line_1'].setValue(addressObject.address_line_1);
        this.tradeForm.controls['address_line_2'].setValue(addressObject.address_line_2);
        this.tradeForm.controls['post_code'].setValue(addressObject.post_code);
        this.tradeForm.controls['suburb'].setValue(addressObject.suburb);
        this.tradeForm.controls['town_city'].setValue(addressObject.town_city);
        this.tradeForm.controls['state_region'].setValue(addressObject.state_region);
        this.tradeForm.controls['country'].setValue(addressObject.country);
        if (!addressObject.isStreetAddress) {
          this.tradeForm.controls['address_line_1'].setErrors({'streetRequired': Constants.STREET_NUMBER_REQUIRED});
        }
      }
    } else {
      this.tradeForm.controls['address_line_1'].setValue(this.tf.address_line_1.value);
    }
    var enddate = document.getElementById('address_line_2');
    enddate?.focus();
    // Do some stuff
  }

  handleGeneralAddressChange(address: Address) {
    this.generalForm.controls['address_line_1'].setValue('');
    this.generalForm.controls['address_line_2'].setValue('');
    this.generalForm.controls['post_code'].setValue('');
    this.generalForm.controls['suburb'].setValue('');
    this.generalForm.controls['town_city'].setValue('');
    this.generalForm.controls['state_region'].setValue('');
    this.generalForm.controls['country'].setValue('');
    if (address !== undefined && address.formatted_address !== undefined) {
      let addressObject = this.helperService.addressHandler(address) as GoogleAddressModel;
      if (addressObject) {
        this.generalForm.controls['address_line_1'].setValue(addressObject.address_line_1);
        this.generalForm.controls['address_line_2'].setValue(addressObject.address_line_2);
        this.generalForm.controls['post_code'].setValue(addressObject.post_code);
        this.generalForm.controls['suburb'].setValue(addressObject.suburb);
        this.generalForm.controls['town_city'].setValue(addressObject.town_city);
        this.generalForm.controls['state_region'].setValue(addressObject.state_region);
        this.generalForm.controls['country'].setValue(addressObject.country);
        if (!addressObject.isStreetAddress) {
          this.generalForm.controls['address_line_1'].setErrors({streetRequired: Constants.STREET_NUMBER_REQUIRED});
        }
      }
    } else {
      this.generalForm.controls['address_line_1'].setValue(this.gf.address_line_1.value);
    }
    var enddate = document.getElementById('address_line_2');
    enddate?.focus();
    // Do some stuff
  }

  eligibilityCriteriaPdf() {
    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);

    const url = `${environment.apiUrl}setting/download-eligibility-criteria`
    this.settingServices.getDownloadEligibilityCriteria().subscribe((result: any) => {
      this.globals.rightSidebarAccountLoader = false;
      this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      if (isPlatformBrowser(this.platformId))
        window.open(result, '_blank');
    }, (error: HttpErrorResponse) => {
      this.helperService.handleError(error);
    });
  }

  confirmEmailAddress(event: any) {
    if (event.type == 'keydown') {
      var data = (document.getElementById("registerconfirmemailaddress") as HTMLImageElement).style.display = 'block'
      const element = (document.querySelector('.register-confirm-email-address') as HTMLElement).style.display = 'block';
      let confirmEmail = document.getElementById("registerconfirmemailaddress") as HTMLImageElement;
      confirmEmail.focus();
      const element1 = (document.querySelector('.confirm-email-address-3') as HTMLElement).style.display = 'block';
    } else if (event.type == 'change') {
      var data = (document.getElementById("registerconfirmemailaddress") as HTMLImageElement).style.display = 'block'
      const element = (document.querySelector('.register-confirm-email-address') as HTMLElement).style.display = 'block';
      let confirmEmail = document.getElementById("registerconfirmemailaddress") as HTMLImageElement;
      confirmEmail.focus();
      const element1 = (document.querySelector('.confirm-email-address-3') as HTMLElement).style.display = 'block';
    }
  }

  /************************************
   * Other functions end
   ************************************/

}
