import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {format} from 'date-fns';
import {ResizeEvent} from 'angular-resizable-element';
import {exportPDF, Group} from '@progress/kendo-drawing';
import {SessionModel} from '../models';
import {Base64Service} from '../utils';
import {AlertService, SubscribersService} from '@coreservices';
import {ThemeOptions} from '../theme-options';
import {Router} from '@angular/router';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {GoogleAddressModel} from '../models/google-address.model';
import {CommonConstants as Constants} from '../constants/common-constants';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private global: ThemeOptions,
    private notificationService: AlertService,
    private subscribersService: SubscribersService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  parseFloat(value: any): any {
    if (!isNaN(value)) {
      parseFloat(value);
    } else {
      return 0.0;
    }
  }

  parseInt(value: any): any {
    if (!isNaN(value)) {
      parseInt(value);
    } else {
      return 0;
    }
  }

  isNumber(value: any) {
    return !isNaN(value);
  }

  setToken(sessionModel: SessionModel): void {
    localStorage.removeItem('usertoken');
    if (sessionModel.access_token !== undefined) {
      const base64Value = Base64Service.encode(sessionModel.access_token);
      localStorage.setItem('usertoken', base64Value);
    }
  }

  encodeData(storeData: string, localStorageText: string): void {
    localStorage.removeItem(localStorageText);
    const base64Value = Base64Service.encode(storeData);
    localStorage.setItem(localStorageText, base64Value);
  }

  decodeData(storedData: string): string {
    const normalText = Base64Service.decode(storedData);
    return normalText;
  }

  //#region Error Handeling
  handleError(error: HttpErrorResponse) {

    if (error.status == 422) {
      if (error.error.data[0].message) {
        //this.notificationService.error(error.error.data[0].message);
      }
    } else if (error.status == 401) {
      this.global.rightSidebarQuote = false;
      this.subscribersService.rightDrawerQuoteToggle(this.global.rightSidebarQuote);
    } else if (error.status == 400) {
      this.notificationService.error(error.error.data.message);
    } else {
      if (("" + error) == 'Unauthorized') {
        this.router.navigate(['/'])
      } else if (error.error.data.message) {
        //this.notificationService.error(error.error.data.message);
      }
    }
  }

  handleMultipleErrors(error: HttpErrorResponse) {
    if (error.status === 422) {
      if (error.error !== undefined && error.error.data !== undefined) {
        const msgArr = error.error.data;
        for (const item of msgArr) {
          ////this.notificationService.error(item.message);
        }
      }
    } else {
      if (error.message) {
        ////this.notificationService.error(error.message);
      }
    }
  }

  scrollIntoView(idOfElement: string) {
    if (idOfElement !== null) {

      document.getElementById(idOfElement)!.scrollIntoView({
        behavior: 'smooth'
      });

    }
  }

  //#region general functions
  removeProprtyFromList(list: any) {
    list = list.filter(function (props?: any) {
      if (props.searchValue) {
        delete props.searchValue;
      }

      return true;
    });
  }


  //#endregion

  //#endregion

  /**
   *
   * @param date
   */
  formatDate(date: string): string {
    if (date && date.trim() !== '') {
      return format(new Date(date), 'dd-MM-yyyy');
    } else {
      return '';
    }
  }

  resizeColumn(event: ResizeEvent, columnName: any) {
    if (event.edges.right) {
      const rowWidth = document.getElementsByClassName('header_row');
      const parentWidth = rowWidth[0].clientWidth;
      const childWidth = event.rectangle.width;
      const columnElts = document.getElementsByClassName('header_column_' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = childWidth + 'px';
      }
    }
  }

  /**
   * Export PDF
   */
  public export(pdfComponent: any, fileName: any): void {
    pdfComponent.export().then((group: Group) => exportPDF(group)).then((dataUri?: any) => {
      const base64 = dataUri.replace('data:application/pdf;base64,', '');
      const fileObject = this.dataURLtoFile(dataUri, fileName);
      const file = new Blob([fileObject], {type: 'application/pdf'});
      if (isPlatformBrowser(this.platformId)) {
        const fileURL = window.URL.createObjectURL(fileObject);
        if (window.navigator && 'msSaveOrOpenBlob' in window.navigator) {
          // Type assertion to any as TypeScript doesn't recognize 'msSaveOrOpenBlob' in Navigator
          (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else {
          const blobURL = URL.createObjectURL(file);
          window.open(blobURL);
        }
      }
    });
  }

  public dataURLtoFile(dataurl?: any, filename?: any) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  /**
   * Common Function for the Google Address
   * @param address
   */
  public addressHandler(address: Address) {
    let isStreet = false
    let addressModel = new GoogleAddressModel();
    if (address !== undefined && address.formatted_address !== undefined) {
      let streetAddress = '';
      address.address_components.map((component: any) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "subpremise": {
            streetAddress = streetAddress + component.long_name + "/"
            break;
          }
          case "street_number": {
            isStreet = true;
            streetAddress = streetAddress + component.long_name;
            break;
          }
          case "premise": {
            streetAddress = streetAddress + (streetAddress != '' ? "," : "") + " ";
            break;
          }
          case "route": {
            let addressRoute = component.long_name;
            if (streetAddress != '') {
              addressRoute = streetAddress + ", " + component.long_name;
            }
            addressModel.address_line_1 = addressRoute;
            break;
          }
          case "sublocality_level_3": {
            addressModel.address_line_2 = component.long_name;
            break;
          }
          case "postal_code": {
            addressModel.post_code = component.long_name;
            break;
          }
          case "locality": {
            addressModel.suburb = component.long_name;
            break;
          }
          case "administrative_area_level_1": {
            addressModel.state_region = component.short_name;
            break;
          }
          case "administrative_area_level_2": {
            addressModel.town_city = component.long_name;
            break;
          }
          case "country": {
            addressModel.country = component.long_name;
            break;
          }
        }
        return true;
      });
      addressModel.isStreetAddress = isStreet
      return addressModel;
    }

    return;
  }

  public getErrorImage(event: any, image?: any) {
    if (image == "" || image == undefined) {
      event.target.src = Constants.NO_IMAGE_PRODUCT;
    } else {
      event.target.src = Constants.S3_BUCKET_URL + '/' + image;
    }
  }

  /**
   * Creates a slug from the given string.
   * @param slug The input string to convert into a slug.
   * @returns A slugified string.
   */
  public createSlug(slug: string): string {
    // Regular expression to match allowed characters
    const lettersOnlyPattern = /[^\-\s\p{L}]+/gu; // Remove all characters except letters, hyphen, and spaces
    const spacesDuplicateUnderscorePattern = /[\-\s]+/g; // Remove duplicate dashes (-) and spaces

    // Use the appropriate pattern based on the isAllowNumber flag
    const pattern = lettersOnlyPattern;

    // Convert to lowercase, remove unwanted characters
    slug = slug.toLowerCase().replace(pattern, '');

    // Replace spaces or multiple dashes with a single dash
    slug = slug.replace(spacesDuplicateUnderscorePattern, '-');

    // Trim any leading or trailing dashes
    slug = slug.replace(/^-+|-+$/g, '');

    return slug;
  }

  /**
   * Reverts a slugified string back to a readable format.
   * @param slug The slugified string to revert.
   * @returns A readable string.
   */
  public revertSlug(slug: string | null): string {
    let originalString = '';
    if (slug) {
      // Replace dashes (-) with spaces
      originalString = slug.replace(/-/g, ' ');

      // Optionally capitalize the first letter of each word if needed
      originalString = originalString.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    
    return originalString;
  }
}
