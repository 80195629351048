import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ArticleModel, CommonCategoryModel, PaginationModel, RequestParamModel} from '../models';
import {BaseService} from '../utils';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(public baseService: BaseService) {
  }

  getArticles(requestParaModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.ARTICLE_LIST_URL, requestParaModel).pipe(
      map<any, any>(response => {
        const contactList = new PaginationModel<ArticleModel>();
        const headers = response.headers;
        contactList.setHeaders(headers);
        contactList.data = response.body.data as ArticleModel[];
        return contactList;
      }, (error: any) => {

      })
    )
  }

  getArticlesById(id: number, requestParaModel?: RequestParamModel) {
    return this.baseService.get(`${ApiConstants.ARTICLE_DETAIL_URL}${id}`, requestParaModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  feedBackForArticle(body: any) {
    return this.baseService.post(ApiConstants.ARTICLE_UPDATE_HELP_FULL_URL, body).pipe(
      map<any, any>(response => {
        return response;
      }, (error: any) => {
        return error;
      })
    )
  }
}
