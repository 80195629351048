import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {BlogModel} from '../models/blog.model';
import {CommonCategoryModel} from '../models/common-category.model';
import {PaginationModel} from '../models/pagination.model';
import {RequestParamModel} from '../models/request-params.model';
import {BaseService} from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public baseService: BaseService) {
  }

  getBlog(requestParamModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.BLOG_LIST_URL, requestParamModel).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<BlogModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as BlogModel[];
        return accountList;
      }, (error: any) => {
        return error;
      })
    )
  }

getBlogById(id: any, requestParamModel: any) {
    return this.baseService.get(`${ApiConstants.BLOG_DETAIL_URL}${id}`, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body
      }, (error: any) => {
        return error;
      })
    )
  }

  getCategoryList(requestParamModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.BLOG_CATEGORY_LIST_URL, requestParamModel).pipe
    (map<any, any>(response => {
      const accountList = new PaginationModel<CommonCategoryModel>();
      const headers = response.headers;
      accountList.setHeaders(headers);
      accountList.data = response.body.data as CommonCategoryModel[];
      return accountList;
    }, (error: any) => {
      return error
    }))
  }
}
