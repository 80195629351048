<div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
<div class="samples-modal-popup sample-library-product otp-varification-sidebar">
  <div class="modal-header">
    <h3 class="modal-title">OTP Verification</h3>
  </div>
  <form class="form" [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p>Please enter the OTP to verify your account</p>
      <div class="verification-content flex-row">
        <input class="form-control" formControlName="otp" id="otp"
          [ngClass]="{ 'is-invalid': (verificationForm.controls['otp'].invalid && verificationForm.controls['otp'].touched || submitted) || vf.otp.errors?.apiError, 'is-valid' : !(verificationForm.controls['otp'].invalid)}"
          type='text' [imask]="{mask: '0 0 0 0 0 0',lazy:false}" [unmask]="true" required>
        <span class="invalid-feedback" role="alert"
          *ngIf="(verificationForm.controls['otp'].invalid && verificationForm.controls['otp'].touched || submitted) || vf.otp.errors?.apiError">
          <span *ngIf="vf.otp.errors?.required">Please enter verification code</span>
          <span *ngIf="vf.otp.errors?.apiError">{{vf.otp.errors?.apiError}}</span>
        </span>
      </div>
    </div>
    <div class="resend-otp">
      <span class="links" (click)="resendCode()"> Click here </span> to resend.
    </div>
    <div class="modal-footer">
      <button class="btn-default btn-light" (click)="cancel()" type="button">Back</button>
      <button type="submit" class="btn-default btn-dark">Confirm</button>
    </div>
  </form>
</div>