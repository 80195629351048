import {Injectable} from '@angular/core';
import {BaseService} from "@coreutils";
import {ProductRecommendedColorsModel, PaginationModel, RequestParamModel} from "@coremodels";
import {map} from "rxjs/operators";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class RecommandedColorService {

  constructor(private baseService: BaseService) {
  }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.PRODUCT_RECOMMENDED_COLOR_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<ProductRecommendedColorsModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as ProductRecommendedColorsModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }
}
