import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants as Constants } from 'src/app/core/constants/common-constants';

@Component({
  selector: 'app-carrier-job-decline-modal',
  templateUrl: './carrier-job-decline-modal.component.html',
  styleUrls: ['./carrier-job-decline-modal.component.css']
})
export class CarrierJobDeclineModalComponent implements OnInit {

  @Input() public amount: any;
  constants = Constants;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close(true);
  }

}
