import {Component, OnInit} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {
  ChangeDetectorRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {Router} from '@angular/router';
import {
  UserModel
} from '@coremodels';
import {
  AlertService,
  AuthService,
  CommunicationService,
  HelperService,
  SubscribersService
} from '@coreservices';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';

import {ThemeOptions} from 'src/app/core/theme-options';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  //#region Declare variable
  public currentRoute: string = '';
  public darkHeader: boolean = false;
  public isUserAuthenticated: boolean = false;
  public urlArray: any;
  public userData: UserModel = new UserModel();
  public isCollapsed = true;
  quotesCount: number = 0;
  gathercoCreditCount: number = 0
  orderCount: number = 0;
  public Constants = Constants;
  sampleOrderCount?: number = 0;
  isTradeUser: boolean = false;
  //#endregion
  isMobileResolution!: boolean;
  s3BucketImageURL!: any;
  userProfile!: UserModel;
  gatherCoCredit = 0.0;
  navbarAnimation!: boolean;
  isShowWallingMegaMenu = false;
  megaMenuType: string = ''

  @HostListener('window:resize', ['$event']) onResize($event: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 1199) {
        this.navbarAnimation = true;
        this.isMobileResolution = true;
        this.navbarAnimation = true;
        this.modalService1.dismissAll();
      } else {
        this.isMobileResolution = false;
        this.navbarAnimation = false;
      }
      this.isCollapsed = true;
    }
  }

  redirectFromMegaMenu(flag?: any) {
    if (flag) {
      this.closeMegaMenu(flag)
    }
  }

  public isShowFlooringMegaMenu = false;
  isHoveredFlooring: boolean = true;
  isHoveredWalling: boolean = true;

  constructor(
    private router: Router,
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private authService: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any,
    public renderer: Renderer2,
    private modalService1: NgbModal,
    public helperService: HelperService,
    private communicationService: CommunicationService,
    private alertService: AlertService,
  ) {
    router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
    });
    let sampleOrders = localStorage.getItem('sampleOrder');
    if (sampleOrders != null) {
      sampleOrders = JSON.parse(sampleOrders);
      this.sampleOrderCount = sampleOrders?.length;
    } else {
      this.sampleOrderCount = 0
    }
    this.isUserAuthenticated = this.authService.isUserAuthenticated()

    this.subscriberService.isUserLoggedIn.subscribe((options) => {
      this.sampleOrderCount = 0
      this.globals.isUserAuthenticated = options;
      this.isUserAuthenticated = options;
      if (this.isUserAuthenticated) {
        this.userData = this.authService.getUserProfile();
        this.isTradeUser = this.userData.role === Constants.USER_TYPE_TRADE;
        if (this.userData.id) {
          this.getCounts()
        }
      } else {
        this.userData = new UserModel();
      }
      this.changeDetectionRef.markForCheck();
    });
    this.subscriberService.sampleOrderProduct.subscribe((sampleOrder) => {
      sampleOrder = JSON.parse(sampleOrder);
      this.sampleOrderCount = sampleOrder.length;
    })
    this.subscriberService.headerCountUpdate.subscribe((options) => {
      this.getCounts()
    });
    this.subscriberService.getCountFormLocalStorage.subscribe((data) => {
      if (data) {
        this.getCounts()
      }
    })
    this.subscriberService.headerCreditCountUpdate.subscribe((options) => {
      this.getCounts()
    });

    this.subscriberService.isDisplayMegaMenu.subscribe((response) => {
      setTimeout(() => {
        if (this.isShowFlooringMegaMenu) {
          this.isShowFlooringMegaMenu = response;
        }
        if (this.isShowWallingMegaMenu) {
          this.isShowWallingMegaMenu = response
        }
      }, 500);
    });
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    if (window.innerWidth <= 1199) {
      this.navbarAnimation = true;
    } else {
      this.navbarAnimation = false;
    }

    this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    this.userData = this.authService.getUserProfile();
    this.isUserAuthenticated = this.authService.isUserAuthenticated();
    if (this.isUserAuthenticated) {
      this.getCounts()
    }

    const userModal = this.authService.getUserProfile();
    if (userModal) {
      this.isTradeUser = userModal.role === Constants.USER_TYPE_TRADE;
    }

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scrollEvent, true);
    }
    this.subscriberService.quoteOrderCount.subscribe((data) => {
      if (data) {
        this.getCounts()
      }
    })
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.scrollEvent, true);
    }
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  scrollEvent = (event: any): void => {
    let scrollPosition = 0;
    if (event.srcElement.scrollingElement) {
      scrollPosition = event.srcElement.scrollingElement.scrollTop;
    }
    if (event.target['scrollingElement'] != undefined) {
      if (scrollPosition >= 80) {

        this.urlArray = this.router.url.split('/');
        if (this.urlArray.length > 0 && typeof this.urlArray[1] != undefined && this.urlArray[1] === 'term-of-service') {
          this.darkHeader = false;
          var termsService = document.getElementById('t&c')
          termsService?.classList.add('term-condition')
        } else {
          this.darkHeader = true;
        }
      } else {
        this.darkHeader = false;
        var termsService = document.getElementById('t&c')
        termsService?.classList.remove('term-condition')
      }
    }
  }

  rightSidebarToggle() {
    this.globals.registrationForm = '';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  logout() {
    this.authService.clearToken();
    this.isUserAuthenticated = false;
    this.globals.isUserAuthenticated = false;
    this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated)
    this.router.navigate(['/']);
  }

 headerWithoutBannerFn() {
    this.urlArray = this.router.url.split('/');
     if (this.urlArray.length > 0 && typeof this.urlArray[1] !== 'undefined') {
      const headerWithoutBannerAllowURL = ['', 'login', 'reset-password', 'gather-commitment', 'samples', 'price-list', 'resource', 'contact-us', 'project', 'blog', 'our-story', 'tiles-for-smiles', 'help-center', 'term-of-service', 'flooring', 'walling','404'];
      // Conditions for project
      if (this.urlArray[1] === 'project' && this.urlArray.length >= 3) {
        return true
      }

      else if (this.urlArray[1] === '404') {
        return true
      }

      else if (this.urlArray[1] === 'blog' && this.urlArray.length == 3) {
        return true
      }

      // Conditions for flooring
      else if (this.urlArray[1] === 'flooring' && this.urlArray.length >= 5) {
        return { withoutBanner: false, darkHeader: true };
      }

      // Conditions for walling
      else if (this.urlArray[1] === 'walling' && this.urlArray.length >= 5) {
        return { withoutBanner: false, darkHeader: true };
      }

      // Conditions for account
      else if (this.urlArray[1] === 'account' && this.urlArray.length >= 1) {
        return { withoutBanner: false, darkHeader: true };
      }

      // For URLs not in the allowed list
      else if (!headerWithoutBannerAllowURL.includes(this.urlArray[1])) {
        return false
      }

      // Default return if none of the conditions match
      return false
    }

    // Return false as a default if urlArray is empty or undefined
    return false
  }


  isWhiteIcon() {
    this.urlArray = this.router.url.split('/');
    if (this.urlArray.length > 0 && typeof this.urlArray[1] != undefined) {
      const headerWithoutBannerAllowURL = ['price-list', 'tiles-for-smiles', 'samples', 'gather-commitment', 'blog', 'help-center'];
      return headerWithoutBannerAllowURL.includes(this.urlArray[1]);
    }
    return false;
  }

  rightSidebarToggleSignIn() {
    this.globals.registrationForm = '';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  rightSidebarToggleSignUp() {
    this.globals.registrationForm = 'trade';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  isActiveMenu(url: string) {
    const routeArray = this.router.url.split("/");
    if (routeArray && routeArray[1] === url) {
      return 'active';
    }
    return '';
  }

  openSampleOrderSidebar() {
    setTimeout(() => {
      this.subscriberService.rightDrawerSampleOrderToggle(true);
    }, 500)
    this.communicationService.emitSampleOrderRightSidebarClick(true);
  }

  getCounts() {
    this.orderCount = localStorage.getItem('orderTotalCount') ? (+localStorage.getItem('orderTotalCount')!) : 0;
    this.quotesCount = localStorage.getItem('quotesActiveCount') ? (+localStorage.getItem('quotesActiveCount')!) : 0;
    this.gathercoCreditCount = localStorage.getItem('gathercoCredit') ? (+localStorage.getItem('gathercoCredit')!) : 0;
    this.changeDetectionRef.detectChanges()
  }

  onMouseEnterMenu(menuType: string) {
    if (menuType == 'flooring') {
      this.isShowFlooringMegaMenu = true;
      setTimeout(() => {
        this.isHoveredFlooring = false;
      }, 200);
    } else {
      this.isShowWallingMegaMenu = true;
      setTimeout(() => {
        this.isHoveredWalling = false;
      }, 200);
    }
  }

  closeMegaMenu(menuType: string) {
    if (menuType == 'flooring') {
      setTimeout(() => {
        this.isHoveredFlooring = true;
        setTimeout(() => {
          this.isShowFlooringMegaMenu = false;
        }, 200);
      }, 200);
    } else {
      setTimeout(() => {
        this.isHoveredWalling = true;
        setTimeout(() => {
          this.isShowWallingMegaMenu = false;
        }, 200);
      }, 200);
    }
  }

  onMouseEnterCloseMegaMenu() {
    this.isShowFlooringMegaMenu = false;
    this.subscriberService.megaMenuType('');
    this.changeDetectionRef.detectChanges();
  }

  unAuthenticatePriceListErrorMessage() {
    this.alertService.error(Constants.PREVENT_ACCESS_PRICE_LIST_ERROR_MESSAGE);
  }

  /************************************
   * Other functions end
   ************************************/
}
