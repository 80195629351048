import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseService} from '@coreutils';
import {PaginationModel, QuoteOrdersModel, QuoteReviewModel, RequestParamModel, ResponseModel} from '@coremodels';
import {SubscribersService} from '@coreservices';
import {ThemeOptions} from '../theme-options';
import {HttpClient} from "@angular/common/http";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class QuoteOrdersService {

  constructor(private baseService: BaseService, private subscribersService: SubscribersService, public Global: ThemeOptions, private http: HttpClient) {
  }

  getQuoteOrders(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<QuoteOrdersModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as QuoteOrdersModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  getQuoteData(quoteID: number, requestParamModel?: RequestParamModel): Observable<QuoteOrdersModel> {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_DETAIL_URL + quoteID, requestParamModel)
      .pipe(map<any, QuoteOrdersModel>(response => {
        return response.body.data as QuoteOrdersModel;
      }, (err: any) => {
        return err;
      }));
  }

  checkExportData(requestBody: any): any {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_CHECK_EXPORT_ID_URL, requestBody)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  checkGuestExportData(requestBody: any): any {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_CHECK_EXPORT_ID_URL, requestBody)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  createQuoteOrder(bodyData: any): any {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_CREATE_URL, bodyData);
  }

  saveQuoteOrder(modelData: any): Observable<number> {
    if (modelData.id !== undefined) {
      return this.baseService.patch(ApiConstants.QUOTE_ORDER_UPDATE_URL + modelData.id, modelData)
        .pipe(map<any, number>(response => {
            modelData = response.data as QuoteOrdersModel;
            return modelData.id;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post(ApiConstants.QUOTE_ORDER_CREATE_URL + '/create', modelData)
        .pipe(map<any, number>(response => {
            modelData = response.data as QuoteOrdersModel;
            return modelData.id;
          }, (err: any) => {
            return err;
          })
        );
    }
  }

  /**
   * ON APPLY CREDIT TO QUOTE
   */
  onCreditApply(body: any) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_APPLIED_CREDIT_URL, body).pipe(map(response => {
      return response;
    }, (err: any) => {
      return err;
    }))
  }

  changeStatus(idQuoteOrder: number, requestData: any): Observable<number> {
    return this.baseService.patch(ApiConstants.QUOTE_ORDER_STATUS_UPDATE_URL + idQuoteOrder, requestData)
      .pipe(map<any, number>((response: any) => {
        let quoteOrder = response.data as QuoteOrdersModel;
        return quoteOrder.id;
      }, (err: any) => {
        return err;
      }));
  }

  saveQuoteOrders(quoteOrderModel: any): Observable<number> {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_CREATE_URL, quoteOrderModel)
      .pipe(map<any, number>(response => {
          quoteOrderModel = response.data as QuoteOrdersModel;
          return quoteOrderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteQuoteOrders(_id: any) {
    return this.baseService.delete(ApiConstants.QUOTE_ORDER_DELETE_URL + _id)
      .pipe(map<any, number>(response => {
        return response;
      }, (err: any) => {
        return err;
      }));
  }

  saveQuoteProduct(quoteProductModel: any): Observable<number> {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_PRODUCT_CREATE_URL, quoteProductModel)
      .pipe(map<any, number>(response => {
          quoteProductModel = response.data as any;
          return quoteProductModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  saveQuoteProductFromSidebar(accountModel: any): Observable<number> {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_PRODUCT_CREATE_URL, accountModel)
      .pipe(map<any, number>(response => {
          accountModel = response.data as any;
          return accountModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteQuoteProducts(_id: any) {
    return this.baseService.delete(ApiConstants.QUOTE_ORDER_PRODUCT_DELETE_URL + _id);
  }

  getQuotePayment(formData: any): Observable<any> {
    return this.baseService.uploadPost(ApiConstants.QUOTE_ORDER_PAYMENT_LIST_URL, formData)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  getCreditCardTransaction(quoteOrderModel: QuoteOrdersModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_CALCULATE_CARD_CHARGE_URL, quoteOrderModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }


  quoteValidateToken(formData: any): Observable<any> {
    return this.baseService.uploadPost(ApiConstants.QUOTE_ORDER_PAYMENT_VALIDATE_TOKEN_URL, formData)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  saveShippingPrice(accountModel: any): Observable<number> {
    return this.baseService.patch('shipping-update/' + accountModel.id, accountModel)
      .pipe(map<any, number>(response => {
        accountModel = response.data as any;
        return accountModel.id;
      }, (err: any) => {
        return err;
      }));
  }

  getTruckDetail(requestParamModel: any) {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_TRUCK_TYPE_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getShippingCost(requestParamModel: any) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_SHIPPING_CALCULATION_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getGCOFCompany(id: any, requestParamModel: RequestParamModel) {
    return this.baseService.get('gatherco-credit/' + id, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  addReviewToQuote(quoteReviewModel: QuoteReviewModel) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_FEEDBACK_URL, quoteReviewModel).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  quoteExportPDF(idQuote: number, formData: any) {
    return this.baseService.uploadPost(ApiConstants.QUOTE_ORDER_EXPORT_URL + idQuote, formData).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  orderReceiptExport(idQuote: number, formData: any) {
    return this.baseService.uploadPost(ApiConstants.QUOTE_ORDER_EXPORT_RECEIPT_URL + idQuote, formData).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  getQuoteDetailByExportId(quoteID: number, requestParamModel?: RequestParamModel): Observable<QuoteOrdersModel> {
    return this.baseService.getWithoutToken(ApiConstants.QUOTE_ORDER_DETAIL_BY_ID_URL, requestParamModel)
      .pipe(map<any, QuoteOrdersModel>(response => {
        return response.body.data as QuoteOrdersModel;
      }, (err: any) => {
        return err;
      }));
  }

  getOrderDetailByTrackingId(id: number, requestParamModel?: RequestParamModel): Observable<QuoteOrdersModel> {
    return this.baseService.getWithoutToken(ApiConstants.TRACK_ORDER_DETAIL_BY_ID_URL, requestParamModel)
      .pipe(map<any, QuoteOrdersModel>(response => {
        return response.body.data as QuoteOrdersModel;
      }, (err: any) => {
        return err;
      }));
  }

  reactiveQuote(param: any) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_REACTIVATE_URL, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getAddressIdByOfOrder(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.USER_UPDATE_ADDRESS_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getCounter(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_COUNTER_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getTotalSampleProductCounter(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_SAMPLE_PRODUCT_COUNTER_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  // save user if new then add if old then update
  sampleAddUnleashed(bodyData: any): Observable<number> {
    return this.baseService.post('quote-order/pushed-sample-order-unleashed', bodyData)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  downloadQuoteOrder(param: any) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_DOWNLOAD_URL, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  downloadQuoteOrderNew(body: any) {
    return this.baseService.getDownloadFileContent(ApiConstants.QUOTE_ORDER_DOWNLOAD_FILE_URL + `${body.file_name}`);
  }

  // downloadQuoteOrder(body: any) {
  //   return this.baseService.getDownloadFileContent(`quote-order/download?id=${body.id}&type=${body.type}`);
  // }

  shareQuoteOrder(param: any) {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_SHARE_URL, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getQuoteOrderPayments(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken('quote-payment', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  confirmCarrierJob(requestBody: any): any {
    return this.baseService.post('quote-order/confirm-job', requestBody)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  downloadPDF(url: any): any {
    return this.baseService.getDownloadFileContent(url);
  }

  saveQuoteProductAppliedCalculator(quoteProductAppliedCalculatorModel: any): Observable<number> {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_CREATE_URL, quoteProductAppliedCalculatorModel)
      .pipe(map<any, number>(response => {
          quoteProductAppliedCalculatorModel = response.data as any;
          return quoteProductAppliedCalculatorModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteQuoteProductAppliedCalculator(_id: any) {
    return this.baseService.delete(ApiConstants.QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_DELETE_URL + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateQuoteOrder(orderModel: any): Observable<any> {
    return this.baseService.patch(ApiConstants.QUOTE_ORDER_UPDATE_URL + orderModel.id, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrdersModel;
          return orderModel;
        }, (err: any) => {
          return err;
        })
      );
  }

  reCalculateQuotePrice(quoteModel: any): Observable<number> {
    return this.baseService.post(ApiConstants.QUOTE_ORDER_RE_CALCULATE_PRICE_URL, quoteModel)
      .pipe(map<any, number>(response => {
          quoteModel = response.data as any;
          return quoteModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteAllQuoteProductAppliedCalculators(_id: any) {
    return this.baseService.delete(ApiConstants.QUOTE_ORDER_DELETE_ALL_PRODUCT_APPLIED_CALCULATOR_URL + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  generateExportId(id: any, requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_GENERATE_EXPORT_ID_URL + id, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getConsumableDetail(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const regionList = new PaginationModel<QuoteOrdersModel>();
        const headers = response.headers;
        regionList.setHeaders(headers);
        regionList.data = response.body.data as any;
        return regionList;
      }, (err: any) => {
        return err;
      }));
  }

  uploadPaymentReceipt(requestModel: any) {
    return this.baseService.uploadPost(ApiConstants.QUOTE_ORDER_UPLOAD_PAYMENT_RECEIPT_URL, requestModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }


  /************************************
   * Quote/Invoice Live Link functions start
   ************************************/
  downloadQuoteOrderByExportId(param: any) {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_DOWNLOAD_BY_EXPORT_ID_URL, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  downloadQuoteOrderFileByExportId(body: any, exportId: string) {
    return this.baseService.getDownloadFileContent(ApiConstants.QUOTE_ORDER_DOWNLOAD_FILE_BY_EXPORT_ID_URL + `${body.file_name}` + '&id=' + `${exportId}`);
  }

  shareQuoteOrderByExportId(param: any) {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_SHARE_BY_EXPORT_ID_URL, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  shareTrackingLinkById(param: any) {
    return this.baseService.postWithoutToken(ApiConstants.ORDER_SHARE_BY_TRACKING_ID, param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  updateStockRequiredDateById(modelData: any, endpoint: string): Observable<number> {
    return this.baseService.postWithoutToken(endpoint, modelData)
      .pipe(map<any, number>(response => {
          modelData = response.data as QuoteOrdersModel;
          return modelData.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  changeStatusByExportId(requestData: any): Observable<number> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_STATUS_UPDATE_BY_EXPORT_ID_URL, requestData)
      .pipe(map<any, number>((response: any) => {
        let quoteOrder = response.data as QuoteOrdersModel;
        return quoteOrder.id;
      }, (err: any) => {
        return err;
      }));
  }

  getPaymentLinkByExportId(formData: any): Observable<any> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_PAYMENT_LINK_BY_EXPORT_ID_URL, formData)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }


  updateSiteContactDetail(accountModel: any, endpoint: string): Observable<any> {
    return this.baseService.postWithoutToken(endpoint, accountModel)
      .pipe(
        map<any, any>(
          response => {
            accountModel = response.data as QuoteOrdersModel;
            return accountModel;
          }, (err: any) => {
            return err;
          }));
  }

  AddOrUpdateDeliveryInstructions(accountModel: any, endpoint: string): Observable<any> {
    return this.baseService.postWithoutToken(endpoint, accountModel)
      .pipe(map<any, any>(response => {
        accountModel = response.data as QuoteOrdersModel;
        return accountModel
      }, (err: any) => {
        return err;
      }));
  }

  updateQuoteOrderByExportId(orderModel: any): Observable<any> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_UPDATE_BY_EXPORT_ID_URL, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrdersModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  saveQuoteProductAppliedCalculatorByExportId(quoteProductAppliedCalculatorModel: any): Observable<number> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_CREATE_BY_EXPORT_ID_URL, quoteProductAppliedCalculatorModel)
      .pipe(map<any, number>(response => {
          quoteProductAppliedCalculatorModel = response.data as any;
          return quoteProductAppliedCalculatorModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteQuoteProductAppliedCalculatorByExportId(exportId: any, _id: any) {
    return this.baseService.deleteWithoutToken(ApiConstants.QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_DELETE_BY_EXPORT_ID_URL + '/' + exportId + '/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  reCalculateQuotePriceByExportId(quoteModel: any): Observable<number> {
    return this.baseService.postWithoutToken(ApiConstants.QUOTE_ORDER_RE_CALCULATE_PRICE_BY_EXPORT_ID_URL, quoteModel)
      .pipe(map<any, number>(response => {
          quoteModel = response.data as any;
          return quoteModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  uploadPaymentReceiptByExportId(requestModel: any) {
    return this.baseService.uploadPostWithoutToken(ApiConstants.QUOTE_ORDER_UPLOAD_PAYMENT_RECEIPT_URL, requestModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  /************************************
   * Quote/Invoice Live Link functions end
   ************************************/
}
