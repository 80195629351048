import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2, ViewEncapsulation
} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AccountsService, AuthService, HelperService, SubscribersService} from '@coreservices';
import {ThemeOptions} from 'src/app/core/theme-options';
import {AccountModel, RequestParamModel, UserModel} from '@coremodels';
import {BaseService} from 'src/app/core/utils/base.service';
import {PaginationModel, QuoteOrdersModel} from '@coremodels';
import {DOCUMENT, isPlatformServer} from "@angular/common";
import {HttpErrorResponse} from '@angular/common/http';
import {CommonConstants as Constants} from "src/app/core/constants/common-constants";
import {CommunicationService} from 'src/app/core/services/communication.service';
import {Meta} from "@angular/platform-browser";
import {environment} from "../environments/environment.prod";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  /* ################### Variable Declaration Star ################### */
  public currentRoute: string = '';
  isLoading: boolean = false;
  userData: any;
  isTradeUser: any;
  quotesActiveCount: any;
  gatherCoCreditCount: any
  orderTotalCount: any;
  faviouriteProductCount: any;
  isBrowser: any;
  isServer: any;
  userProfile!: UserModel;
  gatherCoCredit = 0.0;
  is_sidebar_open: boolean = false;
  is_sample_order_sidebar_open: boolean = false;
  s3BucketImageURL?: any;
  isPageDetailLoad = true;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.renderer.removeClass(document.body, 'scroll-disabled');
  }

  /* ################### Variable Declaration End ################### */

  constructor(
    private router: Router,
    private authService: AuthService,
    private global: ThemeOptions,
    private subscribersService: SubscribersService,
    private baseService: BaseService,
    private accountServices: AccountsService,
    public helperService: HelperService,
    public changeDetection: ChangeDetectorRef,
    public renderer: Renderer2,
    private communicationService: CommunicationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private meta: Meta,
  ) {
    this.setMetaTags()
    router.events.subscribe((event) => {
      this.renderer.removeClass(document.body, 'scroll-disabled');
      this.currentRoute = this.router.url.trim();
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    if (isPlatformServer(platformId)) {
      this.isLoading = true;
      this.isPageDetailLoad = false;
    } else {
      this.isPageDetailLoad = true;
    }
  }

  setMetaTags(){
    if(environment.includeFacebookScript){
      this.addFacebookPixelScript();
    }
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  async ngOnInit() {
    /* ################### Update Count Start ################### */
    this.communicationService.rightSidebarClick.subscribe((eventData: boolean) => {
      this.handleRightSidebarClick(eventData);
    });
    this.communicationService.sampleOrderRightSidebarClick.subscribe((eventData: boolean) => {
      this.handleSampleOrderRightSidebarClick(eventData);
    });
    this.userData = this.authService.getUserProfile();
    if (this.userData) {
      this.isTradeUser = this.userData.role === Constants.USER_TYPE_TRADE;
    }
    if (this.userData?.id) {
      this.getQuoteCount();
      this.getOrderCount();
      this.getGatherCoCredit();
    }
    this.subscribersService.getCountFormLocalStorage.subscribe((data) => {
      if (data) {
        this.getQuoteCount();
        this.getOrderCount();
        this.getGatherCoCredit();
      }
    })
    this.subscribersService.isUserLoggedIn.subscribe((data) => {
      if (data == true) {
        this.userData = this.authService.getUserProfile();
        this.getQuoteCount();
        this.getOrderCount();
        this.getGatherCoCredit();
      }
    })
    /* ################### Update Count End ################### */

    this.global.isUserAuthenticated = this.authService.isUserAuthenticated();
    this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
  }

  ngAfterViewInit() {
  }

  /************************************
   * Life cycle hooks end
   ************************************/


  /************************************
   * Other functions start
   ************************************/

  addFacebookPixelScript(): void {
    // const script = this.renderer.createElement('script');
    // script.innerHTML = `
    //   !function(f,b,e,v,n,t,s)
    //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //     n.queue=[];t=b.createElement(e);t.async=!0;
    //     t.src=v;s=b.getElementsByTagName(e)[0];
    //     s.parentNode.insertBefore(t,s)}(window, document,'script',
    //     'https://connect.facebook.net/en_US/fbevents.js');
    //   fbq('init', '975553447276140');
    //   fbq('track', 'PageView');
    // `;
    // this.renderer.appendChild(this.document.head, script);
  }

  checkTemplateRequired() {
    const routeArray = this.router.url.split('/');
    if (routeArray.length >= 4 && routeArray[1] == 'checkout' && routeArray[2] == 'payment' && +routeArray[3] > 0) {
      return false;
    } else if (routeArray.length >= 5 && routeArray[1] == 'guest-checkout' && routeArray[2] == 'payment') {
      return false;
    } else if (routeArray.length >= 3 && routeArray[1] == 'quote-invoice') {
      return false;
    } else if (routeArray.length >= 3 && routeArray[1] == 'track-order') {
      return false;
    } else if (this.currentRoute.trim() === '/404') {
      return true;
    } else {
      return true;
    }
  }

  async getQuoteCount() {
    const requestParamModel = new RequestParamModel();
    requestParamModel.addDefaultFilter('frontend_user_id', this.userData.id, 'number');
    requestParamModel.addDefaultFilter('type', Constants.QUOTE_TYPE_QUOTE, 'number');
    requestParamModel.addDefaultFilter('is_expired', Constants.QUOTE_IS_EXPIRED_NO, 'number');
    requestParamModel.addDefaultFilter('is_draft', Constants.QUOTE_IS_NOT_DRAFT, 'number');
    requestParamModel.addDefaultFilter('is_deleted', '0', 'number');
    requestParamModel.fields = ['id'];
    requestParamModel.sortChange('id', 'asc');
    requestParamModel.pageSize = 1;
    await this.baseService
      .get('quote-order', requestParamModel)
      .subscribe((response: any) => {
        const responseList = new PaginationModel<QuoteOrdersModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        this.quotesActiveCount = responseList.totalCount
        localStorage.setItem("quotesActiveCount", this.quotesActiveCount)
        this.subscribersService.setActiveQuotesCount(this.quotesActiveCount)
        this.subscribersService.quoteOrderCountToggle(true)
        return responseList;
      }, (error: any) => {
        return error;
      })

  }

  async getOrderCount() {
    /************************************
     * Get all orders start
     ************************************/
    const orderRequestParamModel = new RequestParamModel();
    orderRequestParamModel.addDefaultFilter(
      'frontend_user_id',
      this.userData.id,
      'number'
    );
    orderRequestParamModel.addDefaultFilter(
      'quote_status',
      Constants.ORDER_TAB_ALL_SHORT,
      'number'
    );
    orderRequestParamModel.addDefaultFilter('is_accepted', Constants.QUOTE_IS_ACCEPTED_YES, 'number');
    orderRequestParamModel.addDefaultFilter('is_expired', Constants.QUOTE_IS_EXPIRED_NO, 'number');
    orderRequestParamModel.addDefaultFilter('is_deleted', '0', 'number');
    orderRequestParamModel.fields = ['id'];
    orderRequestParamModel.pageSize = 1;
    await this.baseService
      .get('quote-order', orderRequestParamModel)
      .subscribe((response: any) => {
        const responseList = new PaginationModel<QuoteOrdersModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        this.orderTotalCount = responseList.totalCount
        localStorage.setItem("orderTotalCount", this.orderTotalCount)
        this.subscribersService.quoteOrderCountToggle(true)
        return responseList;
      }, (error: any) => {
        return error;
      })
    /************************************
     * Get all orders end
     ************************************/
  }

  getGatherCoCredit() {
    this.userProfile = this.authService.getUserProfile();
    if (this.userProfile.company_id != undefined) {
      const requestParamContestModel = new RequestParamModel();
      requestParamContestModel.expands = ['gathercoCredit'];
      requestParamContestModel.fields = ['gathercoCredit.available_credit'];
      delete requestParamContestModel.pageSize;
      this.isLoading = true;
      this.accountServices
        .getAccountDetails(
          this.userProfile.company_id,
          requestParamContestModel
        )
        .subscribe(
          (account: AccountModel) => {
            if (
              account &&
              account.gathercoCredit &&
              account.gathercoCredit.available_credit > 0
            ) {
              this.gatherCoCreditCount = account.gathercoCredit?.available_credit
              localStorage.setItem("gathercoCredit", this.gatherCoCreditCount)
              this.changeDetection.detectChanges()
              this.subscribersService.headerCreditCountUpdateToggle(true);

            } else {
              this.gatherCoCredit = 0.0;
            }
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            this.helperService.handleError(error);
            this.isLoading = false;
          }
        );
    }
  }

  handleRightSidebarClick(eventData: boolean) {
    this.is_sidebar_open = eventData;
  }

  handleSampleOrderRightSidebarClick(eventData: boolean) {
    this.is_sample_order_sidebar_open = eventData;
  }

  /************************************
   * Other functions end
   ************************************/

}
