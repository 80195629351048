import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ProjectModel} from '../models/feture-project.mode';
import {PaginationModel} from '../models/pagination.model';
import {ProjectCategoryModel} from '../models/project-category.model';
import {RequestParamModel} from '../models/request-params.model';
import {BaseService} from '../utils/base.service';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class FeatureProjectService {

  constructor(
    private baseService: BaseService,
  ) {
  }

  public API_CONSTANT_CATEGORY = 'project-category';
  public API_CONSTANT = 'project';

  getProjectsCategory(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.PROJECT_CATEGORY_LIST_URL, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectCategoryModel[];
        return accountList;
      }, (error: any) => {
        return error;
      })
    )
  }


  getProjects(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(ApiConstants.FEATURE_PROJECT_LIST_URL, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectModel[];
        return accountList;
      }, (error: any) => {
        return error;
      })
    )
  }

  getProjectById(id: number, requestParamModel?: RequestParamModel) {
    return this.baseService.get(`${ApiConstants.FEATURE_PROJECT_DETAIL_URL}/${id}`, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body.data
      }, (error: any) => {
        return error;
      })
    )
  }


}
