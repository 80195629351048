<div class="samplep-order-confirmation">
  <div class="modal-body">
    <button type="button" class="btn-close close pull-right clsoe-btn" aria-label="Close"
      (click)="ActiveModel.close();">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
    <div class="main-description">
      <p id="textHtml" class="description-label">Premium samples are our gift to you.</p>
      <p class="description">
        Although we’d love to offer a re-collection service, considerable logistical hurdles make this unfeasible.<br>
        We’ve been particularly conscious to only apply landfill biodegradable packaging and would kindly request that
        you thoughtfully repurpose, gift, or dispose of any samples that become redundant.
      </p>
      <button class="accept-btn btn" [disabled]="isAcceptButtonClicked" (click)="onAccept()">Accept</button>
    </div>
  </div>
</div>
