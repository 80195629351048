import {NgModule} from '@angular/core';
import {InitialNavigation, PreloadingStrategy, Route, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {NotFoundComponent} from "./core/components/not-found/not-found.component";
import {Observable, of} from "rxjs";

export class PreloadPublicModules implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: HomeComponent,
  },
  {
    path: 'reset-password/:token/:email',
    component: HomeComponent,
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./refund-policy/refund-policy.module').then(m => m.RefundPolicyModule)
  },
  {
    path: 'quote/example',
    loadChildren: () => import('./quote-sample/quote-sample.module').then(m => m.QuoteSampleModule)
  },
  {
    path: 'project',
    loadChildren: () => import('./feature-project/feature-project.module').then(m => m.FeatureProjectModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'help-center',
    loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  // {
  //   path: 'surfaces',
  //   loadChildren: () => import('./surface/surface.module').then(m => m.SurfaceModule),
  // },
  {
    path: 'resource',
    loadChildren: () => import('./resource-hub/resource-hub.module').then(m => m.ResourceHubModule),
  },
  {
    path: 'our-story',
    loadChildren: () => import('./our-story/our-story.module').then(m => m.OurStoryModule),
  },
  {
    path: 'tiles-for-smiles',
    loadChildren: () => import('./tile-for-smile/tile-for-smile.module').then(m => m.TileForSmileModule),
  },
  {
    path: 'price-list',
    loadChildren: () => import('./price-list/price-list.module').then(m => m.PriceListModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
  },
  {
    path: 'term-of-service',
    loadChildren: () => import('./term-of-service/term-of-service.module').then(m => m.TermOfServiceModule),
  },
  {
    path: 'gather-commitment',
    loadChildren: () => import('./gather-commitment/gather-commitment.module').then(m => m.GatherCommitmentModule),
  },
  {
    path: 'samples',
    loadChildren: () => import('./sample/sample.module').then(m => m.SampleModule),
  },
  {
    path: 'quote-invoice',
    loadChildren: () => import('./quote-invoice/quote-invoice.module').then(m => m.QuoteInvoiceModule),
  },
  {
    path: 'track-order',
    loadChildren: () => import('./track-order/track-order.module').then(m => m.TrackOrderModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
  },

  {
    path: 'guest-checkout/payment/:export_id/:payment_type',
    loadChildren: () => import('./guest-checkout/guest-checkout.module').then(m => m.GuestCheckoutModule)
  },

  {
    path: 'guest/carrier/:export_id/:status',
    loadChildren: () => import('./guest-carrier/guest-carrier.module').then(m => m.GuestCarrierModule)
  },

  {
    path: 'payment/payment-response',
    loadChildren: () => import('./payment-response/payment-response.module').then(m => m.PaymentResponseModule)
  },
  // {
  //   path: 'flooring',
  //   loadChildren: () => import('./flooring/flooring.module').then(m => m.FlooringModule),
  // },
  // {
  //   path: 'walling',
  //   loadChildren: () => import('./walling/walling.module').then(m => m.WallingModule),
  // },
  {
    path: 'outlet',
    loadChildren: () => import('./outlet/outlet.module').then(m => m.OutletModule)
  },
  {
    path: '',
    component: HomeComponent,
  },
  { path: '404', component: NotFoundComponent, data: {hideHeader: true} },
  {
    path: '',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled' as InitialNavigation,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadPublicModules
    })
  ],
  exports: [RouterModule],
  providers: [PreloadPublicModules]
})
export class AppRoutingModule {
}
