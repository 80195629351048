import {HttpErrorResponse} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList, Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
  FrontendUserAddressModel,
  PaginationModel,
  RequestParamModel,
  UserModel,
} from '@coremodels';
import {
  AlertService,
  AuthService,
  CommunicationService,
  HelperService,
  QuoteOrdersService,
  SubscribersService,
} from '@coreservices';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {ConfirmModalService} from 'src/app/core/components/confirm-modal/confirm-modal.service';
import {SampleOrderConfirmationComponent} from 'src/app/shared/modal/sample-order-confirmation/sample-order-confirmation.component';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {GoogleAddressModel} from 'src/app/core/models/google-address.model';
import {ThemeOptions} from 'src/app/core/theme-options';
import {format} from 'date-fns';
import { WaSampleRequestRestrictionModalComponent } from '../../modal/wa-sample-request-restriction-modal/wa-sample-request-restriction-modal.component';

@Component({
  selector: 'app-sample-order-right-sidebar',
  templateUrl: './sample-order-right-sidebar.component.html',
  styleUrls: ['./sample-order-right-sidebar.component.css']
})
export class SampleOrderRightSidebarComponent implements OnInit {

  /* ################### Variable Declaration Start ################### */
  options = {
    componentRestrictions: {country: 'AU'},
    strictBounds: true,
    fields: ['ALL'],
    types: ['address'],
  };
  isLoading!: boolean;
  rightDrawerToggle!: boolean;
  userModel!: UserModel;
  sampleOrders!: any[];
  s3BucketImageURL!: any;
  IMAGE_SIZE_69_X_69 = Constants.IMAGE_SIZE_69_X_69;
  informationShow!: boolean;
  userDetails!: UserModel;
  selectAddress?: number;
  isShowAddressForm?: boolean;
  frontendUserAddressForm!: UntypedFormGroup;
  cartQuoteForm!: UntypedFormGroup;
  submitted!: boolean;
  submittedMain!: boolean;
  radioButtonAddress!: QueryList<ElementRef>;
  todayTotalSampleOrders = 0;
  invalidPostalCode: boolean = false;
  @ViewChild("placesRef") placesRef?: GooglePlaceDirective;
  sampleProductPrice = 0;

  /* ################### Variable Declaration End ################### */

  constructor(
    private authService: AuthService,
    private subscriberService: SubscribersService,
    private globals: ThemeOptions,
    private confirmModalService: ConfirmModalService,
    public helperService: HelperService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private quoteOrdersService: QuoteOrdersService,
    private changeDetectionRef: ChangeDetectorRef,
    private communicationService: CommunicationService,
    public ngbService: NgbModal,
    public renderer: Renderer2
  ) {
    this.subscriberService.isUserLoggedIn.subscribe((options) => {
      if (options) {
        this.userModel = this.authService.getUserProfile();
      }
    });
    this.subscriberService.rightDrawerSampleOrder.subscribe((options) => {
      if (options) {
        this.informationShow = false;
        if (window.innerWidth <= 600) {
          document.body.style.overflow = 'hidden';
        }
      } else {
        if (window.innerWidth <= 600) {
          document.body.style.overflow = '';
        }
      }
      this.globals.rightSidebarSampleOrder = options;
      this.rightDrawerToggle = options;
      this.userModel = this.authService.getUserProfile();
      if (options) {
        this.getAddresses();
      }
      let sampleOrdersString = localStorage.getItem('sampleOrder');
       if (sampleOrdersString != null) {
        this.sampleOrders = JSON.parse(sampleOrdersString);
         this.sampleOrders.map((element: any) => {
          return (element.expandMoreDetails = false);
        });
      } else {
        this.sampleOrders = [];
      }
      this.changeDetectionRef.markForCheck();
     });
}

  /************************************
   * Life cycle hooks start
   ************************************/

  ngOnInit(): void {
    this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
    this.userModel = this.authService.getUserProfile();
    this.subscriberService.isUserLoggedIn.subscribe((data) => {
      if (data == true) {
        this.getAddresses();
      }
    });
    this.cartQuoteForm = this.fb.group({
      project_name: ['', [Validators.required]],
      description: [''],
      site_contact_name: ['', [Validators.required]],
      site_contact_number: ['', [Validators.required, Validators.minLength(10), this.mobileValidator.bind(this)]],
      site_contact_email: ['', [Validators.email]],
    });
    this.frontendUserAddressForm = this.fb.group({
      address_line_1: ['', [Validators.required, this.addressValidator.bind(this)]],
      address_line_2: '',
      suburb: ['', Validators.required],
      town_city: ['', Validators.required],
      state_region: ['', Validators.required],
      post_code: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.patchValueForRecipientDetail()
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/

  patchValueForRecipientDetail() {
    this.cartQuoteForm?.patchValue({
      site_contact_name: this.userModel.first_name + ' ' + this.userModel.last_name,
      site_contact_number: this.userModel.mobile,
      site_contact_email: this.userModel.email,
    });
  }

  private mobileValidator(control: FormControl): { [s: string]: boolean } {
    if (this.cartQuoteForm && control.value !== '' && control.value !== null) {
      let $mobileNumber = control.value;
      if ($mobileNumber.substring(0, 2) == '04') {
        return {};
      }
    }
    return {mobileNotValid: true};
  }

  getFileName(str: any) {
    var fileName = '';
    if (str != undefined && str !== null && str !== '') {
      var newFileName = str.split('\\').pop().split('/').pop(); // Get file name
      newFileName = newFileName.split("/").slice(-1).join().split(".").shift(); // Remove extension
      fileName = newFileName.replace(/_/g, ' '); // Replace underscore(_) with dash(-)
    }
    return fileName;
  }

  private addressValidator(control: UntypedFormControl): { [s: string]: boolean } {
    let addressRegex = Constants.PO_BOX_ADDRESS_REGEX;

    if (this.frontendUserAddressForm && control.value !== '' && control.value !== null) {
      let $addressLine1 = control.value;
      if (!addressRegex.test($addressLine1)) {
        return {};
      }
    }
    return {addressNotValid: true};
  }

  toggleSidebar() {
    this.globals.rightSidebarSampleOrder = false;
    this.subscriberService.rightDrawerSampleOrderToggle(this.globals.rightSidebarSampleOrder);
    setTimeout(() => {
      this.communicationService.emitSampleOrderRightSidebarClick(false);
    }, 500);
  }

  toggleMoreDetailsExpand(quoteProductID: number, flag: boolean) {
    this.sampleOrders.forEach((element: any) => {
      if (element.id === quoteProductID) {
        element.expandMoreDetails = !flag;
      }
      return element;
    });
    this.changeDetectionRef.detectChanges();
  }

  deleteQuoteProduct(quoteProductID: number) {
    this.confirmModalService
      .confirm(
        Constants.CONFIRMATION_CART_PRODUCT_DELETE_TITLE,
        Constants.CONFIRMATION_CART_PRODUCT_DELETE_MESSAGE
      )
      .then((confirmed) => {
        if (confirmed) {
          this.isLoading = true;
          const indexQuoteProduct = this.sampleOrders.findIndex((ele: any) => {
            return +ele.id === quoteProductID;
          });
          if (indexQuoteProduct > -1) {
            this.sampleOrders.splice(indexQuoteProduct, 1);
          }
          this.subscriberService.sampleOrderProductToggle(
            JSON.stringify(this.sampleOrders)
          );
          localStorage.setItem(
            'sampleOrder',
            JSON.stringify(this.sampleOrders)
          );
          this.isLoading = false;
          this.changeDetectionRef.detectChanges();
        }
      });
  }

  get fua() {
    return this.frontendUserAddressForm.controls;
  }

  get cq() {
    return this.cartQuoteForm.controls;
  }

  checkoutProceed() {
    this.informationShow = true;
  }

 onChangeAddress(idAddress: any) {
    if (idAddress > 0) {
      this.submitted = false;
      this.selectAddress = idAddress;
      this.isShowAddressForm = false;
      this.frontendUserAddressForm.reset();
      this.cartQuoteForm.controls.delivery_address_id.setValue(idAddress);
    } else {
      this.cartQuoteForm.controls.delivery_address_id.setValue('');
      this.isShowAddressForm = true;
    }
    this.changeDetectionRef.detectChanges();
  }

  getAddresses() {
    this.isLoading = true;
    if (this.userModel && this.userModel.id != undefined) {
      this.isLoading = true;
      const requestParamContestModel = new RequestParamModel();
      requestParamContestModel.expands = [
        'frontendUserAddresses.fullAddress',
        'company',
        'company.defaultAddress',
        'company.companyAddresses',
      ];
      delete requestParamContestModel.pageSize;
      this.authService
        .getUserDetails(this.userModel.id, requestParamContestModel)
        .subscribe(
          (userModel: UserModel) => {
            this.userDetails = userModel;

            if (this.userDetails?.company?.defaultAddress) {
              this.patchValueForOrganization(
                this.userDetails?.company?.defaultAddress
              );
              this.isLoading = false;
            } else if (
              this.userDetails?.company?.companyAddresses &&
              this.userDetails?.company?.companyAddresses.length > 0
            ) {
              this.patchValueForOrganization(
                this.userDetails?.company?.companyAddresses[0]
              );
              this.isLoading = false;
            }
          },
          (error: HttpErrorResponse) => {
            this.helperService.handleError(error);
            this.isLoading = false;
          }
        );
    }
    this.isLoading = false;
  }

  patchValueForOrganization(deliveryAddress: any) {
    this.frontendUserAddressForm.controls['address_line_1'].setValue(
      deliveryAddress?.address_line_1 ? deliveryAddress?.address_line_1 : ''
    );
    this.frontendUserAddressForm.controls['address_line_2'].setValue(
      deliveryAddress?.address_line_1 ? deliveryAddress?.address_line_2 : ''
    );
    this.frontendUserAddressForm.controls['post_code'].setValue(
      deliveryAddress?.post_code ? deliveryAddress?.post_code : ''
    );
    this.frontendUserAddressForm.controls['suburb'].setValue(
      deliveryAddress?.suburb ? deliveryAddress?.suburb : ''
    );
    this.frontendUserAddressForm.controls['town_city'].setValue(
      deliveryAddress?.town_city ? deliveryAddress?.town_city : ''
    );
    this.frontendUserAddressForm.controls['state_region'].setValue(
      deliveryAddress?.state_region ? deliveryAddress?.state_region : ''
    );
    this.frontendUserAddressForm.controls['country'].setValue(
      deliveryAddress?.country ? deliveryAddress?.country : ''
    );
    this.checkPostalCode(this.frontendUserAddressForm.controls['post_code'].value)
  }

  public handleAddressChange(address: Address) {
    this.frontendUserAddressForm.controls['address_line_1'].setValue('');
    this.frontendUserAddressForm.controls['address_line_2'].setValue('');
    this.frontendUserAddressForm.controls['post_code'].setValue('');
    this.frontendUserAddressForm.controls['suburb'].setValue('');
    this.frontendUserAddressForm.controls['town_city'].setValue('');
    this.frontendUserAddressForm.controls['state_region'].setValue('');
    this.frontendUserAddressForm.controls['country'].setValue('');
    if (address !== undefined && address.formatted_address !== undefined) {
      let addressObject = this.helperService.addressHandler(
        address
      ) as GoogleAddressModel;
      if (addressObject) {
        this.frontendUserAddressForm.controls['address_line_1'].setValue(
          addressObject.address_line_1
        );
        this.frontendUserAddressForm.controls['address_line_2'].setValue(
          addressObject.address_line_2
        );
        this.frontendUserAddressForm.controls['post_code'].setValue(
          addressObject.post_code
        );
        this.frontendUserAddressForm.controls['suburb'].setValue(
          addressObject.suburb
        );
        this.frontendUserAddressForm.controls['town_city'].setValue(
          addressObject.town_city
        );
        this.frontendUserAddressForm.controls['state_region'].setValue(
          addressObject.state_region
        );
        this.frontendUserAddressForm.controls['country'].setValue(
          addressObject.country
        );
        if (!addressObject.isStreetAddress) {
          this.frontendUserAddressForm.controls['address_line_1'].setErrors({
            streetRequired: Constants.STREET_NUMBER_REQUIRED,
          });
        }
      }
    } else {
      this.frontendUserAddressForm.controls['address_line_1'].setValue(
        this.fua.address_line_1.value
      );
    }
    var enddate = document.getElementById('address_line_2');
    enddate?.focus();
    this.checkPostalCode(this.frontendUserAddressForm.controls['post_code'].value)
  }

  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    if (this.frontendUserAddressForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.userModel.id) {
      var frontendUserAddress: FrontendUserAddressModel = {
        frontend_user_id: this.userModel.id,
        address_line_1: this.fua.address_line_1.value,
        address_line_2: this.fua.address_line_2.value,
        suburb: this.fua.suburb.value,
        town_city: this.fua.town_city.value,
        state_region: this.fua.state_region.value,
        post_code: this.fua.post_code.value,
        country: this.fua.country.value,
      };

      this.authService.updateUserAddressDetails(frontendUserAddress).subscribe(
        (addressModel: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.frontendUserAddressForm.reset();
          this.frontendUserAddressForm.markAsUntouched();
          this.getAddresses();
          const self = this;
          setTimeout(function () {
            self.onChangeAddress(addressModel.id);
            self.isShowAddressForm = false;
            self.radioButtonAddress.forEach((element) => {
              element.nativeElement.checked =
                +element.nativeElement.id === +addressModel.id ? true : false;
            });
            self.cartQuoteForm.controls.delivery_address_id.setValue(
              addressModel.id
            );
          }, 500);
          this.alertService.success(
            Constants.SUCCESS_FRONTEND_USER_ADDRESS_CREATE
          );
        },
        (error: HttpErrorResponse) => {
          this.submitted = false;
          this.isLoading = false;
          if (error.error !== undefined && error.error.data !== undefined) {
            const msgArr = error.error.data;
            for (const item of msgArr) {
              this.alertService.error(item.message);
            }
          }
        }
      );
    }
  }

  onSubmitQuote() {
    this.isLoading = true;
    this.submittedMain = true;
    if (this.cartQuoteForm.invalid || this.frontendUserAddressForm.invalid) {
      this.isLoading = false;
      return;
    }
    const instance = this.ngbService.open(SampleOrderConfirmationComponent, {
      centered: false,
      windowClass: 'looking-for-more',
      backdrop: 'static',
      backdropClass: 'sample-confirmation-modal-backdrop',
      keyboard: false,
    });
    instance.closed.subscribe((data) => {
      if (data) {
        this.submittedMain = false;
        var quoteForm: any = {
          frontend_user_id: this.userModel.id,
          project_name: this.cq.project_name.value,
          description: this.cq.description.value,
          type: Constants.QUOTE_TYPE_SAMPLE_ORDER,
          delivery_suburb: this.fua.suburb.value,
          delivery_post_code: this.fua.post_code.value,
          delivery_state_region: this.fua.state_region.value,
          delivery_town_city: this.fua.town_city.value,
          delivery_country: this.fua.country.value,
          delivery_address_line_1: this.fua.address_line_1.value,
          delivery_address_line_2: this.fua.address_line_2.value,
          site_contact_name: this.cq.site_contact_name.value,
          site_contact_number: this.cq.site_contact_number.value,
          site_contact_email: this.cq.site_contact_email.value,
        };
        this.quoteOrdersService.saveQuoteOrder(quoteForm).subscribe(
          (_id: any) => {
            let k = 0;
            if (this.sampleOrders.length > 0) {
              this.sampleOrders.forEach((element: any) => {
                if (element.quantity > 0) {
                  let quoteOrderProduct: any = {
                    quote_id: _id,
                    product_id: element.id,
                    quantity: element.quantity,
                  };
                  this.quoteOrdersService
                    .saveQuoteProduct(quoteOrderProduct)
                    .subscribe(
                      (_id1: any) => {
                        k++;
                        if (k == this.sampleOrders.length) {
                          this.afterSampleOrderConfirmation();
                        }
                      },
                      (error: any) => {
                        this.isLoading = false;
                        this.helperService.handleError(error);
                      }
                    );
                } else {
                  k++;
                  if (k == this.sampleOrders.length) {
                    this.afterSampleOrderConfirmation();
                  }
                }
              });
            }
          },
          (error: any) => {
            this.isLoading = false;
            this.helperService.handleError(error);
          }
        );
      } else {
        this.isLoading = false;
      }
    });
  }

  afterSampleOrderConfirmation() {
    this.router.navigate(['/account/samples']);
    this.isLoading = false;
    this.cartQuoteForm.reset();
    this.submittedMain = false;
    this.alertService.success(Constants.SAMPLE_CREATED_SUCCESS_MESSAGE);
    localStorage.removeItem('sampleOrder');
    this.subscriberService.rightDrawerSampleOrderToggle(false);
    this.subscriberService.sampleOrderProductToggle(JSON.stringify([]));
  }

  counter(i: number) {
    return new Array(i);
  }

  checkPostalCode(code:any){
    if(code.startsWith(Constants.WA_POSTAL_CODE_PREFIX)){
      let modalRef = this.ngbService.open(WaSampleRequestRestrictionModalComponent, {
        centered: true,
        windowClass: 'donationModals quotes-model quote-order-export-pdf-model',
        backdrop: 'static',
      });
      modalRef.closed.subscribe((result: any) => {
        if (result) {
          this.frontendUserAddressForm.reset();
          this.invalidPostalCode = true;
        }
      });
    }else {
      this.invalidPostalCode = false;
    }
  }
  /************************************
   * Other functions end
   ************************************/
}
