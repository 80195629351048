export class PaginationModel<T> {
  pageCount!: number;
  pageSize!: number;
  totalCount!: number;
  currentPage!: number;
  data!: T[];

  setHeaders(headers: import('@angular/common/http').HttpHeaders): any {
    this.totalCount = +headers.get('X-Pagination-Total-Count')!;
    this.pageSize = +headers.get('X-Pagination-Per-Page')!;
    this.pageCount = +headers.get('X-Pagination-Page-Count')!;
    this.currentPage = +headers.get('X-Pagination-Current-Page')!;
  }
}

