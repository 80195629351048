import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalInput]'
})
export class DecimalsInputDirective {
  myInput: any;
  private regex: RegExp = new RegExp(/^[+-]?\d*\.?\d{0,2}$/g);
  //#region Variables
  private navigationKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Escape",
    "Enter",
    "Home",
    "End",
    "ArrowLeft",
    "ArrowRight",
    "Clear",
    "Copy",
    "Paste"
  ];
  inputElement?: HTMLElement;
  //#endregion

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
   }

     //#region Listener
  @HostListener("keydown", ["$event"])
  /**
   * Key Down Event
   */
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.ctrlKey === true && e.key === "a") || // Allow: Ctrl+A
      (e.ctrlKey === true && e.key === "c") || // Allow: Ctrl+C
      (e.ctrlKey === true && e.key === "v") || // Allow: Ctrl+V
      (e.ctrlKey === true && e.key === "x") || // Allow: Ctrl+X
      (e.metaKey === true && e.key === "a") || // Allow: Cmd+A (Mac)
      (e.metaKey === true && e.key === "c") || // Allow: Cmd+C (Mac)
      (e.metaKey === true && e.key === "v") || // Allow: Cmd+V (Mac)
      (e.metaKey === true && e.key === "x") // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), e.key == 'Decimal' ? '.' : e.key, current.slice(position)].join('');
    
    if (next && !String(next).match(this.regex)) {
      e.preventDefault();
    }
  }
}
