import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribersService {

  rightDrawer: Subject<boolean> = new Subject<boolean>();
  rightDrawerSurface: Subject<boolean> = new Subject<boolean>();
  rightDrawerDecor: Subject<boolean> = new Subject<boolean>();
  rightDrawerQuote: Subject<boolean> = new Subject<boolean>();
  rightDrawerSampleOrder: Subject<boolean> = new Subject<boolean>();
  rightDrawerPriceListQuote: Subject<boolean> = new Subject<boolean>();
  leftDrawerSurface: Subject<boolean> = new Subject<boolean>();
  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  isAccountLoader: Subject<boolean> = new Subject<boolean>();
  registrationTabForm: Subject<string> = new Subject<string>();
  resetPasswordForm: Subject<boolean> = new Subject<boolean>();
  profilePicture: Subject<string> = new Subject<string>();
  companyPicture: Subject<string> = new Subject<string>();
  refreshProductPages: Subject<boolean> = new Subject<boolean>();
  sampleOrderProduct: Subject<string> = new Subject<string>();
  headerCountUpdate: Subject<boolean> = new Subject<boolean>();
  headerCreditCountUpdate: Subject<boolean> = new Subject<boolean>();
  quoteOrderCount: Subject<boolean> = new Subject<boolean>();
  surfaceAccessoriesData: Subject<any> = new Subject<any>();
  getCountFormLocalStorage: Subject<any> = new Subject<any>();
  collectionAndSizeSelection: Subject<boolean> = new Subject<boolean>();
  registrationTabFormWithData: Subject<string> = new Subject<string>();
  menuType: Subject<string> = new Subject<string>();

  private verificationTabFormSubject = new BehaviorSubject<any>(null);
  public verificationTabForm = this.verificationTabFormSubject.asObservable();
  private activeQuotesCountSubject = new BehaviorSubject<any>(0);
  public activeQuotesCount = this.activeQuotesCountSubject.asObservable();
  private isDisplayMegaMenuSubject = new BehaviorSubject<any>(null);
  public isDisplayMegaMenu = this.isDisplayMegaMenuSubject.asObservable();

  constructor() {
  }

  getCountFromLocalStorageToggle(flag: boolean) {
    this.getCountFormLocalStorage.next(flag)
  }

  quoteOrderCountToggle(flag: boolean) {
    this.quoteOrderCount.next(flag);
  }

  rightDrawerToggle(flag: boolean) {
    this.rightDrawer.next(flag);
  }

  userLoggedInToggle(flag: boolean) {
    this.isUserLoggedIn.next(flag);
  }

  rightSidebarAccountLoaderToggle(flag: boolean) {
    this.isAccountLoader.next(flag);
  }

  registrationFormToggle(formType: string) {
    this.registrationTabForm.next(formType);
  }

  resetPasswordFormToggle(flag: boolean) {
    this.resetPasswordForm.next(flag);
  }

  profilePictureToggle(path: string) {
    this.profilePicture.next(path);
  }

  companyPictureToggle(path: string) {
    this.companyPicture.next(path);
  }

  rightDrawerSurfaceToggle(flag: boolean) {
    this.rightDrawerSurface.next(flag);
  }

  rightDrawerDecorToggle(flag: boolean) {
    this.rightDrawerDecor.next(flag);
  }

  rightDrawerQuoteToggle(flag: boolean) {
    this.rightDrawerQuote.next(flag);
  }

  leftDrawerSurfaceToggle(flag: boolean) {
    this.leftDrawerSurface.next(flag);
  }

  refreshProductPagesToggle(flag: boolean) {
    this.refreshProductPages.next(flag);
  }

  sampleOrderProductToggle(str: string) {
    this.sampleOrderProduct.next(str);
  }

  rightDrawerSampleOrderToggle(flag: boolean) {
    this.rightDrawerSampleOrder.next(flag);
  }

  rightDrawerPriceListQuoteToggle(flag: boolean) {
    this.rightDrawerPriceListQuote.next(flag);
  }

  headerCountUpdateToggle(flag: boolean) {
    this.headerCountUpdate.next(flag);
  }

  surfaceAccessoriesDataToggle(data: any) {
    this.surfaceAccessoriesData.next(data);
  }

  collectionAndSizeToggle(flag: boolean) {
    this.collectionAndSizeSelection.next(flag);
  }

  verificationFormToggle(data: any) {
    this.verificationTabFormSubject.next(data);
  }

  registrationFormToggleWithData(data: any) {
    this.registrationTabFormWithData.next(data);
  }

  headerCreditCountUpdateToggle(flag: boolean) {
    this.headerCreditCountUpdate.next(flag);
  }

  megaMenuType(data: any) {
    this.menuType.next(data);
  }

  setActiveQuotesCount(data: any){
    this.activeQuotesCountSubject.next(data);
  }

  displayMegaMenu(flag: boolean){
    this.isDisplayMegaMenuSubject.next(flag);
  }
}
