import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ResetPasswordModel, ResponseModel, SessionModel, SettingModel, UserModel } from '@coremodels';

import {
  AlertService,
  AuthService,
  FaviouritesService,
  HelperService,
  SpinnerService,
  SubscribersService
} from '@coreservices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

import { CommonConstants as Constants } from 'src/app/core/constants/common-constants';
import { ThemeOptions } from 'src/app/core/theme-options';
import { environment } from 'src/environments/environment';
import { ProductFavouriteSuccessModalComponent } from 'src/app/core/components/product-favourite-success-modal/product-favourite-success-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../auth.css']
})
export class LoginComponent implements OnInit {

  /* ################### Variable Declaration Start ################### */
  @Input() is_cross: boolean = false
  @Input() loginTab: boolean = false
  isEmailFocused: boolean = false;
  loginTabForm: boolean = true;
  forgotPasswordTabForm: boolean = false;
  resetPasswordTabForm: boolean = false;

  submitted = false;
  errorMessage = '';
  resetToken: string = '';
  emailAddress: string = '';
  isDisplayLoginNote: boolean = true;

  loginForm!: UntypedFormGroup;
  forgotPassword!: UntypedFormGroup;
  resetPassword!: UntypedFormGroup;
  redirectURL!: string;

  /* ################### Variable Declaration End ################### */

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthService,
    private helperService: HelperService,
    private alertService: AlertService,
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private favoriteService: FaviouritesService,
    private modalService: NgbModal,
    private socialAuthService: SocialAuthService,
    public spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public renderer: Renderer2,
  ) {
    this.subscriberService.resetPasswordForm.subscribe((options) => {
      this.globals.resetPasswordForm = options;
      if (this.globals.resetPasswordForm == true) {
        this.resetPasswordTabForm = options;
        this.loginTabForm = false;

        this.forgotPasswordTabForm = false;
      } else {
        this.switchLogin()
        this.resetPasswordTabForm = options;
        this.forgotPasswordTabForm = false;
      }
      if (this.loginForm) {
        this.loginForm.reset();
      }
      if (this.forgotPassword) {
        this.forgotPassword.reset();
      }
      if (this.resetPassword) {
        this.resetPassword.reset();
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnChanges() {
    //Write your code here
    if (this.is_cross) {
      setTimeout(() => {
        this.loginTabForm = true;
        this.forgotPasswordTabForm = false;
        this.submitted = false;
        this.loginForm.reset();
      }, 500);
    }

    if (this.loginTab == true) {
      setTimeout(() => {
        if (document.getElementById("confirmemailaddress")) {
          var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'none'
        }
        const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'none';
        this.forgotPassword.reset()
      }, 500);
    }
  }

  ngOnInit(): void {
    let params = this.route.snapshot.queryParams;
    if (params['redirectTo']) {
      this.redirectURL = params['redirectTo'];
    }

    const resetPasswordToken = this.router.url.split('/');
    if (resetPasswordToken.length > 0) {
      this.resetToken = resetPasswordToken[resetPasswordToken.length - 2];
      this.emailAddress = resetPasswordToken[resetPasswordToken.length - 1];
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.forgotPassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
      confirm_email: ['', [Validators.required, this.confirmMailTradeMatcher.bind(this)]]
    });
    this.resetPassword = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), this.noWhitespaceValidator]],
      confirm_password: ['', [Validators.required, this.passwordMatcher.bind(this), this.noWhitespaceValidator]]
    });

    this.ff.email.valueChanges.subscribe(() => this.ff.confirm_email.updateValueAndValidity());
    this.loginForm.reset();
    this.forgotPassword.reset();
    this.resetPassword.reset();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/

  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f() {
    return this.loginForm.controls;
  }

  get ff() {
    return this.forgotPassword.controls;
  }

  get fr() {
    return this.resetPassword.controls;
  }

  private confirmMailTradeMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.forgotPassword && (control.value !== this.forgotPassword.controls.email.value)) {
      return { confirmEmailNotMatch: true };
    }
    return {};
  }

  onSubmit() {
    this.errorMessage = '';
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    var loginModel = {
      username: encodeURIComponent(this.f.email.value),
      password: this.f.password.value,
      client_id: environment.frontendClientId,
      client_secret: environment.frontendClientSecret,
      grant_type: environment.grantType
    }
    this.authenticationService.login(loginModel).subscribe(
      (sessionModel: SessionModel) => {
        localStorage.removeItem('sampleOrder')
        if (sessionModel.access_token !== undefined) {
          this.helperService.setToken(sessionModel);

          localStorage.setItem("usertoken", sessionModel.access_token);
          this.authenticationService.getLoginUserDetail().subscribe(
            (userModel: UserModel) => {
              /* ################### Set Login User Detail Start ################### */
              localStorage.setItem("user", JSON.stringify(userModel));
              this.submitted = false;
              this.globals.isUserAuthenticated = true;
              this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated);
              this.globals.rightSidebar = false;
              this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);

              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
              this.loginForm.reset();
              // Remove scroll disable class
              this.renderer.removeClass(document.body, 'scroll-disabled');
              /* ################### Set Login User Detail Start ################### */

              /* ################### Redirection Based On Condition Start ################### */
              if (localStorage.getItem('accept_require')) {
                this.router.navigate(['/account/quotes/' + localStorage.getItem('accept_require')]);
              } else if (localStorage.getItem('delivery_date_required')) {
                const id = localStorage.getItem('delivery_date_required');
                this.router.navigate(['/account/orders/' + localStorage.getItem('delivery_date_required') + '?order_status=AAW'])
              } else if (localStorage.getItem('redirect_url')) {
                var redirectURL = localStorage.getItem('redirect_url');
                localStorage.removeItem('redirect_url');
                if (redirectURL) {
                  this.router.navigateByUrl(redirectURL);
                }
              }
              /* ################### Redirection Based On Condition End ################### */

              /* ################### Insert Favourite Product Start ################### */
              let favoriteProductID = localStorage.getItem('product_id_favorite');
              if (favoriteProductID != undefined && favoriteProductID != '' && favoriteProductID != '') {
                var bodyData = {
                  product_id: +favoriteProductID
                }
                this.favoriteService.updateProductFaviourite(bodyData).subscribe((response: any) => {
                  let currentUrl = localStorage.getItem('product_favorite_redirect');
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([currentUrl]);
                  });
                  this.globals.rightSidebarAccountLoader = false;
                  this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
                  const modalRef = this.modalService.open(ProductFavouriteSuccessModalComponent, {
                    size: 'sm',
                    centered: true,
                    windowClass: 'favourite-modal',
                  });
                  modalRef.componentInstance.message = Constants.SUCCESS_PRODUCT_ADDED_FOR_FAVORITE;
                  localStorage.removeItem('product_id_favorite');
                  localStorage.removeItem('product_favorite_redirect');
                }, (error: HttpErrorResponse) => {
                  if (error.error !== undefined && error.error.data !== undefined) {
                    const msgArr = error.error.data;
                    for (const item of msgArr) {
                      this.alertService.error(item.message);
                    }
                  }
                });
              }
              /* ################### Insert Favourite Product End ################### */
              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            }, (error: any) => {
              if (error.status == 422) {
                this.alertService.error(error, 'Error');
              } else if (error.status == 401) {
                this.errorMessage = error.error.data.message;
                this.alertService.error(this.errorMessage, 'Error');
              }

              this.spinnerService.hide()
              this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            });
        }
      }, (error: any) => {
        this.submitted = false;
        if (error.status == 422) {
          this.alertService.error(error, 'Error');
        } else if (error.status == 401) {
          this.errorMessage = error.error?.data?.message ? error.error?.data?.message : error.error?.message;
          this.alertService.error(this.errorMessage, 'Error');
        }
        this.globals.rightSidebarAccountLoader = false;
        this.spinnerService.hide();
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
  }

  onForgotPasswordSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotPassword.invalid) {
      return;
    }
    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    var forgotPasswordModel = {
      email: this.ff.email.value,
      client_id: environment.frontendClientId
    }
    this.authenticationService.forgotPassword(forgotPasswordModel).subscribe((responseModel: ResponseModel) => {
      this.alertService.success(Constants.SUCCESS_FORGOT_PASSWORD, 'Success');
      this.submitted = false;
      this.loginForm.markAsUntouched();
      this.globals.rightSidebar = false;
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
      this.globals.rightSidebarAccountLoader = false;
      this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'none'
      const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'none';
      this.loginTabForm = true;
      this.forgotPasswordTabForm = false;
      this.forgotPassword.reset();
    }, error => {
      if (error.status == 422) {
        let self = this;
        if (error.error.data.length > 0) {
          Object.keys(error.error.data).forEach(function (key, data) {
            self.forgotPassword.controls[error.error.data[key].field].setErrors({ 'apiError': error.error.data[key].message });
          });
        }
      }
      this.globals.rightSidebarAccountLoader = false;
      this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    });
  }

  onResetPasswordSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    var resetPasswordModel = new ResetPasswordModel();
    resetPasswordModel.password_reset_token = this.resetToken;
    resetPasswordModel.password = this.fr.password.value;
    resetPasswordModel.client_id = environment.frontendClientId;
    this.authenticationService.resetPassword(resetPasswordModel).subscribe((responseModel: ResponseModel) => {
      this.submitted = false;
      this.resetPassword.markAsUntouched();
      this.alertService.success(Constants.SUCCESS_RESET_PASSWORD, 'Success');
      var loginModel = {
        username: this.emailAddress,
        password: this.fr.password.value,
        client_id: environment.frontendClientId,
        client_secret: environment.frontendClientSecret,
        grant_type: environment.grantType
      }
      this.resetPassword.reset();
      this.authenticationService.login(loginModel).subscribe(
        (sessionModel: SessionModel) => {
          if (sessionModel.access_token !== undefined) {
            this.helperService.setToken(sessionModel);
            localStorage.setItem("usertoken", sessionModel.access_token);
            this.authenticationService.getLoginUserDetail().subscribe(
              (userModel: UserModel) => {
                localStorage.setItem("user", JSON.stringify(userModel));
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
                this.submitted = false;
                this.globals.rightSidebar = false;
                this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
                this.globals.isUserAuthenticated = false;
                this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated);
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
                this.resetPasswordTabForm = false;
                this.globals.resetPasswordForm = false;
                this.loginForm.reset();
                this.subscriberService.resetPasswordFormToggle(this.globals.resetPasswordForm);
                this.loginTabForm = true;
                var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'none'
                const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'none';
                this.router.navigate(['/']);
              }, (error: any) => {
                if (error.status == 422) {
                  this.alertService.error(error, 'Error');
                } else if (error.status == 401) {
                  this.errorMessage = error.error.data.message;
                  this.alertService.error(this.errorMessage, 'Error');
                }
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
              });
          }
        }, (error: any) => {
          if (error.status == 422) {
            this.alertService.error(error, 'Error');
          } else if (error.status == 401) {
            this.errorMessage = error.error.data.message;
            this.alertService.error(this.errorMessage, 'Error');
          }
          this.globals.rightSidebarAccountLoader = false;
          this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
        });
    }, (error: HttpErrorResponse) => {
      if (error.status == 422) {
        this.alertService.error(error.error.data.message, 'Error');
      } else if (error.status == 500) {
        this.alertService.error(error.error.data.message, 'Error');
      } else if (error.status == 404) {
        this.alertService.error(error.error.data.message, 'Error');
      }
      this.globals.rightSidebar = false;
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
      this.globals.rightSidebarAccountLoader = false;
      this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      this.loginForm.reset();
      this.loginTabForm = false;

      this.resetPasswordTabForm = false;
      this.globals.resetPasswordForm = false;
      this.subscriberService.resetPasswordFormToggle(this.globals.resetPasswordForm);
      this.router.navigate(['/']);
    });

  }

  private passwordMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.resetPassword && (control.value !== this.resetPassword.controls.password.value)) {
      return { passwordNotMatch: true };
    }
    return {};
  }

  switchLogin() {
    this.loginTabForm = !this.loginTabForm;

    this.forgotPasswordTabForm = !this.forgotPasswordTabForm;
    this.submitted = false;
    this.loginForm.reset();
    var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'none'
    const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'none';
  }

  switchForgotPassword() {
    this.forgotPasswordTabForm = !this.forgotPasswordTabForm;
    this.loginTabForm = !this.loginTabForm;

    this.submitted = false;
    this.forgotPassword.reset();
  }

  fbLogin() {
    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    const fbLoginOptions = {
      scope: 'public_profile,email'
    };
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions).then(
      (userData) => {
        let loginModelFB = {
          access_token: userData.authToken
        };
        this.authenticationService.loginWithFacebook(loginModelFB).subscribe((sessionModel: SessionModel) => {
          if (sessionModel.access_token !== undefined) {
            this.helperService.setToken(sessionModel);
            localStorage.setItem("usertoken", sessionModel.access_token);
            this.authenticationService.getLoginUserDetail().subscribe(
              (userModel: UserModel) => {
                localStorage.setItem("user", JSON.stringify(userModel));
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
                this.submitted = false;
                this.globals.isUserAuthenticated = true;
                this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated);
                this.globals.rightSidebar = false;
                this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
                this.loginForm.reset();
              }, (error: any) => {
                if (error.status == 422) {
                  this.alertService.error(error, 'Error');
                } else if (error.status == 401) {
                  this.errorMessage = error.error.data.message;
                  this.alertService.error(this.errorMessage, 'Error');
                }
                this.globals.rightSidebarAccountLoader = false;
                this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
              });
          }
        }, (error: any) => {
          if (error.status == 422) {
            this.alertService.error(error, 'Error');
          } else if (error.status == 401) {
            this.errorMessage = error.error.data.message;
            this.alertService.error(this.errorMessage, 'Error');
          }
          this.globals.rightSidebarAccountLoader = false;
          this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
        });
      }).catch((error: any) => {
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
  }

  googleLogin() {
    this.globals.rightSidebarAccountLoader = true;
    this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
      let loginModelGL = {
        id_token: userData.idToken
      };
      this.authenticationService.loginWithGoogle(loginModelGL).subscribe((sessionModel: SessionModel) => {
        if (sessionModel.access_token !== undefined) {
          this.helperService.setToken(sessionModel);
          localStorage.setItem("usertoken", sessionModel.access_token);
          this.authenticationService.getLoginUserDetail().subscribe(
            (userModel: UserModel) => {
              localStorage.setItem("user", JSON.stringify(userModel));
              this.globals.rightSidebarAccountLoader = !this.globals.rightSidebarAccountLoader;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
              this.submitted = false;
              this.globals.isUserAuthenticated = true;
              this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated);
              this.globals.rightSidebar = false;
              this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
              this.loginForm.reset();
            }, (error: any) => {
              if (error.status == 422) {
                this.alertService.error(error, 'Error');
              } else if (error.status == 401) {
                this.errorMessage = error.error.data.message;
                this.alertService.error(this.errorMessage, 'Error');
              }
              this.globals.rightSidebarAccountLoader = false;
              this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
            });
        }
      }, (error: any) => {
        if (error.status == 422) {
          this.alertService.error(error, 'Error');
        } else if (error.status == 401) {
          this.errorMessage = error.error.data.message;
          this.alertService.error(this.errorMessage, 'Error');
        }
        this.globals.rightSidebarAccountLoader = false;
        this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
      });
    }).catch((error: any) => {
      this.globals.rightSidebarAccountLoader = false;
      this.subscriberService.rightSidebarAccountLoaderToggle(this.globals.rightSidebarAccountLoader);
    });
  }

  onFocus(event?: any) {
    if (event) {
      this.isEmailFocused = event.target;
    } else {
      this.isEmailFocused = false;
    }
  }

  onFormFieldClick() {
    if ((this.loginForm.value.email !== undefined && this.loginForm.value.email !== null && this.loginForm.value.email != '')) {
      this.isDisplayLoginNote = false;
    } else {
      this.isDisplayLoginNote = true;
    }
    this.changeDetectionRef.detectChanges();
  }

  confirmEmailAddress(event: any) {
    if (event.type == 'keydown') {
      var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'block'
      const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'block';
      let confirmEmail = document.getElementById("confirmemailaddress") as HTMLImageElement;
      confirmEmail.focus();
      const element1 = (document.querySelector('.confirmn-email-address-4') as HTMLElement).style.display = 'block';

    } else if (event.type == 'change') {
      var data = (document.getElementById("confirmemailaddress") as HTMLImageElement).style.display = 'block'
      const element = (document.querySelector('.confirm-email-address') as HTMLElement).style.display = 'block';
      let confirmEmail = document.getElementById("confirmemailaddress") as HTMLImageElement;
      confirmEmail.focus();
      const element1 = (document.querySelector('.confirmn-email-address-4') as HTMLElement).style.display = 'block';
    }
  }

  /************************************
   * Other functions end
   ************************************/

}
