<div class="delete-favourite-modal">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <p id="textHtml">{{message}} </p>
  </div>
  <div class="modal-footer">
    <div class="footer-btn-grp">
      <button class="btn grey-bg-btn" data-dismiss="modal" (click)="decline()">
        {{ cancelButtonText }}
      </button>
      <button class="btn grey-bg-btn delete-confirm" data-dismiss="modal" (click)="confirm()">
        {{ confirmationButtonText }}
      </button>
    </div>
  </div>
</div>
