<div class="signin-register-panel"
     [ngClass]="[rightDrawerToggle ? 'active-panel' : '', !isPageDetailLoad ? 'd-none': '']">
  <div class="panel-header">
    <span class="icon-close" (click)="rightSidebarToggle()"></span>
    <div class="account-type">
      <ul class="nav nav-tabs">
        <li class="nav-item">
            <span class="nav-link" [ngClass]="{active: formType == ''}" id="tab_signin"
                  (click)="loginForm();onTabChange('signIn');">Sign in </span>
        </li>
        <li class="nav-item">
            <span class="nav-link" [ngClass]="{active: formType != ''}" id="tab_register"
                  (click)="registerForm();onTabChange('register');">Register</span>
        </li>
      </ul>
    </div>
  </div>
<div class="panel-body loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !rightSidebarLoaderToggle}"></div>
    <div class="panel-tab-section">
      <div class="tab-content">
        <div class="tab-pane" [ngClass]="{active: formType == ''}" id="signin">
          <app-login [is_cross]="is_cross" [loginTab]="loginTab" *ngIf="formType == ''"></app-login>
        </div>
        <div class="tab-pane" [ngClass]="{active: (formType != '' && formType != 'otp-verification')}" id="register">
          <app-register *ngIf="(formType != '' && formType != 'otp-verification')"></app-register>
        </div>
        <div class="tab-pane" [ngClass]="{active: (formType != '' && formType == 'otp-verification')}"
             id="opt-verification">
          <app-verify-account *ngIf="(formType != '' && formType == 'otp-verification')"></app-verify-account>
        </div>
      </div>
    </div>
  </div>
</div>
