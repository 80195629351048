<div class="samplep-order-confirmation">
    <div class="modal-body">
      <div class="main-description">
        <p id="textHtml" class="description-label">Sorry For Inconvenience</p>
        <p class="description">
          Due to significant logistical challenges, we're currently unable to process sample requests and/or ship orders
          to Western Australia for smaller projects. However, for larger projects requiring at least 300m² of stock, we
          can arrange shipment directly to Fremantle Port through our supply network, making it economically viable. If
          your project meets this requirement, we would love to assist. Please feel free to reach out to us at
          <a (click)="openContactEmail()" class="b-0 contact-email"><strong>hi&#64;gatherco.com.au</strong></a>.
        </p>
        <button class="accept-btn btn" (click)="closePopup()">Close</button>
      </div>
    </div>
  </div>
