import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

import {AlertService, AuthService, SubscribersService} from "../services";

import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {of} from 'rxjs';

import {CommonConstants as Constants} from "../constants/common-constants";

// Error Interceptor
@Injectable()
export class BaseInterceptor implements HttpInterceptor {

  /* ################### Constructor Start ################### */
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private subscribersService: SubscribersService,
    public activeRoute: ActivatedRoute
  ) {
  }

  /* ################### Constructor end ################### */

  /************************************
   * Other functions start
   ************************************/

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let pathArray = location.pathname.split('/');
      const error = err?.error?.message || err?.statusText;
      if (pathArray[1] != 'admin') {

        if ([401, 403, 500, 400, 404, 405, 406, 413, 415, 408, 501, 502, 422, 200].indexOf(err.status) !== -1) {

          if (err.status == 403) {

            this.alertService.error(Constants.FORBIDDEN);
            this.router.navigate(['403']);
          } else if (err.status == 401) {
            this.authService.clearToken();
            this.subscribersService.userLoggedInToggle(false);
            if (err.error.message !== undefined) {
              this.alertService.error(err.error.message);
              if (err.error.message == Constants.UN_VALID_CREDENTIAL) {
              } else {
                setTimeout(() => {
                  this.router.navigate(['/'])
                }, 1000);
              }
            } else if (err.error.data !== undefined) {
              setTimeout(() => {
                this.router.navigate(['/'])
              }, 1000);
              this.alertService.error(err.error.data.message);
            } else {

              setTimeout(() => {
                this.router.navigate(['/'])
              }, 1000);
              let errorMsg = err.statusText;
              this.alertService.error(errorMsg);
            }
            let pathArray = location.pathname;
          } else if (err.status == 200) {
            this.alertService.success(error);
            this.router.navigate(["/login"]);
          } else if (err.status == 500) {
            this.alertService.error(Constants.INTERNAL_SERVER_ERROR);
          } else if (err.status == 400) {
            let pathArray = location.pathname.split('/');
            if ((pathArray.length > 3 && pathArray[1] === 'account' && pathArray[2] === 'checkout-payment') || (pathArray.length > 3 && pathArray[1] === 'account' && pathArray[2] === 'payment') || (pathArray.length > 3 && pathArray[1] === 'account' && pathArray[2] === 'quotes')) {
              return throwError(err);
            } else if (pathArray[1] == "guest" && pathArray[2] == "payment") {
            } else {
              if (error == undefined) {
                this.alertService.error(Constants.BED_REQUEST);
              }
            }
          } else if (err.status == 404) {
            let pathArray = location.pathname.split('/');
            if (pathArray.length > 2 && pathArray[1] === 'reset-password') {
              return throwError(err);
            } else if (pathArray[1] === 'surfaces' && (pathArray[2] === 'M' || pathArray[2] === 'F')) {
              return throwError(err);
            } else if (pathArray.length == 3 && pathArray[pathArray.length - 1] === 'gather-commitment' && pathArray[pathArray.length - 2] === 'account') {
              return throwError(err);
            } else if (pathArray.length == 4 && pathArray[pathArray.length - 3] === 'account' && pathArray[pathArray.length - 2] === 'orders') {
              return throwError(err);
            } else {

              if (pathArray.length == null) {
                this.alertService.error(Constants.REQUEST_NOT_FOUND);
                return throwError(err);
              }
            }
          } else if (err.status === 405) {
            this.alertService.error(Constants.METHOD_NOT_ALLOW);
            return of(err);
          } else if (err.status === 406) {
            this.alertService.error(Constants.NOT_ACCEPTABLE);
            return of(err);
          } else if (err.status === 413) {
            this.alertService.error(Constants.REQUEST_ENTITY_TOO_LARGE);
            return of(err);
          } else if (err.status === 415) {
            this.alertService.error(Constants.UNSUPPORTED_MEDIA);
            return of(err);
          } else if (err.status === 408) {
            this.alertService.error(Constants.REQUEST_TIME_OUT);
            return of(err);
          } else if (err.status === 500) {
            this.alertService.error(Constants.INTERNAL_SERVER_ERROR);
            return of(err);
          } else if (err.status === 501) {
            this.alertService.error(Constants.NOT_IMPLEMENTED);
            return of(err);
          } else if (err.status === 502) {
            this.alertService.error(Constants.BED_GATEWAY);
            return of(err);
          } else if (err.status === 503) {
            this.alertService.error(Constants.SERVICE_UNAVAILABLE);
            return of(err);
          } else if (err.status === 504) {
            this.alertService.error(Constants.GATEWAY_TIME_OUT);
            return of(err);
          } else if (err.status == 422) {
            return throwError(err);
          }
        } else {
          this.alertService.error("danger", error);
          return throwError(err);
        }
        return throwError(err);
      } else {
        return throwError(err);
      }

    }))
  }

  /************************************
   * Other functions end
   ************************************/

}
