import {Injectable} from '@angular/core';
import {RequestParamModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from "@coreutils";
import {map} from 'rxjs/operators';
import {FinishModel} from '@coremodels';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class FinishService {

  constructor(private baseService: BaseService) {
  }

  // get all user list
  getFinish(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.FINISH_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<FinishModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as FinishModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }
}
